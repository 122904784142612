import React from "react";
import { useNavigate } from "react-router-dom";

const CursoLogout: React.FC = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('selectedCourse');
        
        navigate('/setofcourse'); // Redirigir al usuario a la página de inicio de sesión
    };

    return (
        <button onClick={handleLogout} className="transition ease-in-out delay-150 bg-orange-400 hover:-translate-y-1 hover:scale-110 hover:bg-amber-400 duration-300 mr-10 text-white rounded-lg pt-3 pb-3 pl-4 pr-4">Salir Curso</button>
    );
};

export default CursoLogout;
