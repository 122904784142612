import React from 'react';
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";


interface PropsBarchartPro{
    promedio: number[];
    examenActual: number[];
  }
  

const PromedioGeneralChart: React.FC<PropsBarchartPro> = ({ promedio, examenActual}) => {
    const options: ApexOptions = {
        chart: {
          type: "bar",
          width: "100%",
          height: 400,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          categories: ["Notas"],
          labels: {
            show: true,
            style: {
              fontFamily: "Inter, sans-serif",
              cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontFamily: "Inter, sans-serif",
              cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
            },
          },
        },
        grid: {
          show: true,
          strokeDashArray: 4,
          padding: {
            left: 2,
            right: 2,
            top: -20,
          },
        },
        fill: {
          opacity: 1,
        },
      };
    
      const series = [
        {
          name: "Nota Actual",
          color: "#31C48D",
          data: examenActual,
        },
        {
          name: "Promedio General",
          data: promedio,
          color: "#FFCA28",
        },
      ];
    
      return (
        <div className="mx-auto w-3/5 overflow-hidden">
            <h1>Promedio General</h1>
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height={400}
          />
        </div>
      );



};

export default PromedioGeneralChart;

