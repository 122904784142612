import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Mode from './components/Mode';
import Login from './Pages/Login/Login';
import SetOfCourses from './components/Course/SetOfCourses';
import TestLayout from './Pages/TestLayout/TestLayout';
import Header from './static/Header';

import AxiosTestLayout from './Pages/TestLayout/AxiosTestLayout';
import Results from './Pages/Results/Results';
import UserForm from './Pages/Admin/CompAdmin/Forms/UserForm';
import CursoForm from './Pages/Admin/CompAdmin/Forms/CursoForm';
import EtiquetasForm from './Pages/Admin/CompAdmin/Forms/EtiquetasForm';
import FormQuestion from './Pages/Admin/CompAdmin/Questions/FormQuestion';
import Footer from './static/Footer';
import PreguntasTable from './Pages/Admin/Tables/PreguntasTable';
import UsersTable from './Pages/Admin/Tables/UsersTable';
import MatriculasTable from './Pages/Admin/Tables/MatriculasTable';

//Prime react
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import AdminMain from './Pages/Admin/Main/AdminMain';


function App() {

  useEffect(() => {
    document.title = 'IPC Community';
  }, []);

  const isUserAuthenticated = (): boolean => {
    const accessToken = sessionStorage.getItem('accessToken')

    return accessToken !== null;
  }


  const modesData = [
    {
      title: 'Modo Estudio',
      icon: '/studymode.svg',
      data_link: 'pruebas/usuario-curso/',
      link: true,
      type: 'pruebas',
      links: [
        { to: 'random', label: 'Aleatorio', type: 'pruebas', },
        { to: 'A', label: 'Por area', type: 'pruebas', },
        { to: 'G', label: 'Por grupos', type: 'pruebas', },
        { to: 'D', label: 'Por dominios', type: 'pruebas', },
        { to: 'E', label: 'Por enfoques', type: 'pruebas', },
      ],
    },
    {
      title: 'Modo Examen',
      icon: '/exammode.svg',
      data_link: 'examenes/usuario-curso/',
      link: true,
      type: 'examenes',
      links: [
        { to: 'random', label: 'Empezar examen', type: 'examenes' },
      ],
    },
    {
      title: 'Resultados',
      icon: '/resultmode.svg',
      data_link: 'resultados/usuario-curso/',
      link: false,
      type: 'resultados',
      links: [
      ],
    },
    // Agrega más modos aquí
  ];

  const isAuthenticated = isUserAuthenticated();
  const router = createBrowserRouter([

    // Rutas principales

    {
      path: `/curso/:course`,
      element: isAuthenticated ? <Mode modes={modesData} /> : <Login />,
    },

    {
      path: "/mode",
      element: isAuthenticated ? <Mode modes={modesData} /> : <Login />,
    },

    {
      path: "/setofcourse",
      element: isAuthenticated ? <SetOfCourses /> : <Login />,
    },

    // Rutas para Formularios
    {
      path: "/admin",
      element: isAuthenticated ? <AdminMain /> : <Login />,
    },
    {
      path: "/cursoForm",
      element: isAuthenticated ? <CursoForm /> : <Login />,
    },
    {
      path: "/userForm",
      element: isAuthenticated ? <UserForm /> : <Login />,
    },
    {
      path: "/etiquetasForm",
      element: isAuthenticated ? <EtiquetasForm /> : <Login />,
    },

    // Rutas para examenes

    {
      path: `/pruebas/usuario-curso/:examenEnRuta`,
      element: isAuthenticated ?
        <AxiosTestLayout /> : <Login />,
    },
    {
      path: `/examenes/usuario-curso/:examenEnRuta`,
      element: isAuthenticated ?
        <AxiosTestLayout /> : <Login />,
    },
    {
      path: `/resultados/usuario-curso/:examenEnRuta`,
      element: isAuthenticated ?
        <Results /> : <Login />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/',
      element: isAuthenticated ? <SetOfCourses /> : <Login />,
    }


  ]);

  return (
    <>
      <div className='min-h-screen'>
        {isAuthenticated && <Header />}
        <RouterProvider router={router} />

      </div>

    </>

  );
}

export default App;
