import React, { useState, useEffect, useRef } from 'react';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

import UsersService from '../../../services/UsersService';
import UserDetail from '../Detail/UserDetail';
import UserCreate from '../Create/UserCreate';
import { UserInterface } from '../../../models/UsersModel';
import UserEdit from '../Edit/UserEdit';

const UsersTable: React.FC = () => {

    const [users, setUsers] = useState<UserInterface[]>([]);
    const [filters, setFilters] = useState<any>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        texto: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const [selectedUser, setSelectedUser] = useState<UserInterface | null>(null);
    const [UserVisible, setUserVisible] = useState<boolean>(false);
    const [createDialog, setCreateDialog] = useState<boolean>(false);
    const [UserEditVisible, setUserEditVisible] = useState<boolean>(false);

    useEffect(() => {
        UsersService.getUsers().then((data) => setUsers(data));
    }, []);

    const tipoBodyTemplate = (rowData: any) => {
        return <Tag value={rowData.is_staff ? 'Administrador' : 'Usuario'} severity={rowData.is_staff ? "success" : "info"} />;
    };

    const opcionesBodyTemplate = (rowData: any) => {

        return (
            <div className="card">
                <div className="flex flex-wrap justify-content-center gap-3">
                    <Button onClick={() => detailUser(rowData)} icon="pi pi-eye" severity="success" aria-label="Search" />
                    {/**<Button onClick={() => editUser(rowData)} icon="pi pi-pencil" severity="secondary" aria-label="Bookmark" />
                    <Button onClick={() => deleteUser(rowData)} icon="pi pi-times" severity="danger" aria-label="Cancel" /> */}

                </div>
            </div>
        )
    };

    const onGlobalFilterChange = (event: any) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className="flex flex-wrap gap-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
                </span>
                <Button label="Nuevo Usuario" icon="pi pi-plus" severity="success" onClick={openNew} />

            </div>
        );
    };

    const openNew = () => {
        setCreateDialog(true);
    };

    const detailUser = (user: UserInterface) => {
        setSelectedUser(user);
        setUserVisible(true);
    }

    const editUser = (user: UserInterface) => {
        setSelectedUser(user);
        setUserEditVisible(true);
    }

    const onResponse = (data: UserInterface) => {
        setUsers([...users, data])
    }

    const header = renderHeader();
    
    return (
        <div className="card border rounded-lg">
            <UserCreate visible={createDialog} setVisible={setCreateDialog} response={onResponse} />
            {/*selectedUser  && UserEditVisible ?
            <>
            <UserEdit visible={UserEditVisible} setVisible={setUserEditVisible} response={onResponse} User={selectedUser} />
            </>:<></>*/}
            {selectedUser ?
                <>
                    <UserDetail visible={UserVisible} setVisible={setUserVisible} user={selectedUser} />
                </>
                :
                <></>
            }
            <DataTable value={users} paginator rows={6} header={header} filters={filters} onFilter={(e) => setFilters(e.filters)}
                selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value as UserInterface)} selectionMode="single" dataKey="id"
                emptyMessage="No se encontraron usuarios." tableStyle={{ minWidth: '50rem' }}>
                <Column field="username" header="Usuario" sortable style={{ width: '40%' }}></Column>
                <Column field="email" header="Correo" sortable filterMenuStyle={{ width: '14rem' }} style={{ width: '20%' }}></Column>
                <Column field="is_staff" header="Tipo" body={tipoBodyTemplate} sortable filterMenuStyle={{ width: '14rem' }} style={{ width: '20%' }}></Column>
                <Column field="id" header="Opciones" body={opcionesBodyTemplate} style={{ width: '20%' }}></Column>
            </DataTable>
        </div>
    );
}
export default UsersTable;