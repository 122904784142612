import { ChangeEvent, useEffect, useState } from "react";
import Alternativas from "./Alternativas";

import EtiquetasService from "../../../../services/EtiquetasService";
import CursosService from "../../../../services/CursosService";
import PreguntasService from "../../../../services/PreguntasService";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";


interface Cursos {
    id: string;
    nombre: string
}

interface Etiqueta {
    id: string;
    nombre: string
}

interface Etiquetas {
    etiquetas: Etiqueta[];
    curso: string
}

interface PropsFormQuestion {
    visible: boolean;
    setVisible: (activo: boolean) => void;
    response: (data: any) => void;
}

const FormQuestion: React.FC<PropsFormQuestion> = ({ visible, setVisible, response }) => {
    const [pregunta, setPregunta] = useState({
        "texto": "",
        "tipo": "",
        "explicacion": "",
        "curso": [""],
        "etiquetas": [""],
        "alternativas": [] as { texto: string; peso: number }[],
    });

    const [tiposOptions, setTiposOptions] = useState<{ value: string; label: string }[]>([]);
    const [cursosOptions, setCursosOptions] = useState<Cursos[]>([]);
    const [cursosSelected, setCursosSelected] = useState<string[]>([]);
    const [etiquetasSelected, setEtiquetasSelected] = useState<string[]>([]);
    const [etiquetasOptions, setEtiquetasOptions] = useState<Etiqueta[]>([]);

    useEffect(() => {
        // Obtener opciones de cursos
        CursosService.getCursos().then((data) => setCursosOptions(data));

        // Obtener opciones de etiquetas
        EtiquetasService.getEtiquetasFull().then((data) => setEtiquetasOptions(data));
        setTiposOptions([
            { value: "V", label: "Verdadero" },
            { value: "F", label: "Falso" },
            { value: "multiple_choice", label: "Opción múltiple" },
            { value: "grouping", label: "Relacionar de uno a uno" },
            { value: "boxes", label: "Agrupar en cajas" },
            { value: "ordering", label: "Ordenamiento" },
            // Agrega más opciones según sea necesario
        ]);
    }, []); // Asegúrate de ajustar la URL de la solicitud

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setPregunta((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEventChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        console.log(name, value);
        const selectedOptions = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        );
        setPregunta((prevState) => ({
            ...prevState,
            [name]: selectedOptions, // Cambiar [option] a selectedOptions
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Aquí puedes hacer algo con los datos de "pregunta", por ejemplo, enviarlos al servidor.
        PreguntasService.postPregunta([pregunta]).then((data) => {
                
            setPregunta({
                "texto": "",
                "tipo": "",
                "explicacion": "",
                "curso": [""],
                "etiquetas": [""],
                "alternativas": [] as { texto: string; peso: number }[],
            });
        
            setCursosSelected([]);
            setEtiquetasSelected([]);

            setVisible(false);
        });

    };

    const handleCancel = (e: React.FormEvent) => {
        e.preventDefault();

        setPregunta({
            "texto": "",
            "tipo": "",
            "explicacion": "",
            "curso": [""],
            "etiquetas": [""],
            "alternativas": [] as { texto: string; peso: number }[],
        });
    
        setCursosSelected([]);
        setEtiquetasSelected([]);

        setVisible(false);

    };

    const handleAlternativasChange = (nuevasAlternativas: {
        texto: string;
        peso: number;
    }[]) => {
        setPregunta((prevState) => ({
            ...prevState,
            alternativas: nuevasAlternativas,
        }));
    };

    const changeCurso = (id: string) => {
        const index = cursosSelected.indexOf(id);


        if (index !== -1) {
            // El ID está presente, eliminarlo
            const newArray = [...cursosSelected]; // Crear una copia del array
            newArray.splice(index, 1);
            setCursosSelected(newArray);
            setPregunta((prevPregunta) => {
                // Crea una copia del objeto prevPregunta
                const newPregunta = { ...prevPregunta };

                // Si el valor anterior de curso es [""] lo sobrescribe, de lo contrario, agrega los cursos seleccionados
                newPregunta.curso = newArray.length === 0 ? [String(id)] : [...newArray];

                // Devuelve la copia actualizada
                return newPregunta;
            }); // Actualizar el estado con la nueva copia
        } else {
            // El ID no está presente, agregarlo
            setCursosSelected([...cursosSelected, id]); // Agregar el ID al array y actualizar el estado
            setPregunta((prevPregunta) => {
                // Crea una copia del objeto prevPregunta
                const newPregunta = { ...prevPregunta };

                // Si el valor anterior de curso es [""] lo sobrescribe, de lo contrario, agrega los cursos seleccionados
                newPregunta.curso = [...cursosSelected, id].length === 0 ? [String(id)] : [...[...cursosSelected, id]];

                // Devuelve la copia actualizada
                return newPregunta;
            });
        }
    }

    const changeEtiqueta = (id: string) => {
        const index = etiquetasSelected.indexOf(id);
        if (index !== -1) {
            // El ID está presente, eliminarlo
            const newArray = [...etiquetasSelected]; // Crear una copia del array
            newArray.splice(index, 1);
            setEtiquetasSelected(newArray); // Actualizar el estado con la nueva copia
            setPregunta((prevPregunta) => {
                // Crea una copia del objeto prevPregunta
                const newPregunta = { ...prevPregunta };

                // Si el valor anterior de curso es [""] lo sobrescribe, de lo contrario, agrega los cursos seleccionados
                newPregunta.etiquetas = newArray.length === 0 ? [String(id)] : [...newArray];

                // Devuelve la copia actualizada
                return newPregunta;
            }); // Actualizar el estado con la nueva copia
        } else {
            // El ID no está presente, agregarlo
            setEtiquetasSelected([...etiquetasSelected, id]); // Agregar el ID al array y actualizar el estado
            setPregunta((prevPregunta) => {
                // Crea una copia del objeto prevPregunta
                const newPregunta = { ...prevPregunta };

                // Si el valor anterior de curso es [""] lo sobrescribe, de lo contrario, agrega los cursos seleccionados
                newPregunta.etiquetas = [...etiquetasSelected, id].length === 0 ? [String(id)] : [...[...etiquetasSelected, id]];

                // Devuelve la copia actualizada
                return newPregunta;
            }); // Actualizar el estado con la nueva copia
        }
    }
    return (
        <div className="card flex justify-content-center">
            <Dialog header="Detalle" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className="mt-5">
                    <div className="md:flex md:flex-row md:space-x-4 w-full text-xs">
                        <div className="w-full flex flex-col mb-3">
                            <label className="font-semibold text-gray-600 py-2">Enunciado de la pregunta</label>
                            <input
                                type="text"
                                id="texto"
                                name="texto"
                                value={pregunta.texto}
                                onChange={handleChange}
                                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 hover:border-amber-400"
                            />
                        </div>
                    </div>
                    <div className="md:flex md:flex-row md:space-x-4 w-full text-xs">
                        <div className="w-full flex flex-col mb-3">
                            <label className="font-semibold text-gray-600 py-2">Tipo<abbr title="required">*</abbr></label>
                            <select
                                id="tipo"
                                name="tipo"
                                value={pregunta.tipo}
                                onChange={handleChange}
                                className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 md:w-full">
                                <option value="">Selecciona un tipo</option>
                                {tiposOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="flex-auto w-full mb-1 text-xs space-y-2 mt-4">
                        <h3 className="mb-4 font-semibold text-gray-900 ">Cursos</h3>
                        <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
                            {cursosOptions.map((curso) => (
                                <li className="w-full border-b border-gray-200 rounded-t-lg ">
                                    <div className="flex items-center pl-3">
                                        <input
                                            onChange={() => changeCurso(curso.id)}
                                            id={curso.id}
                                            type="checkbox"
                                            checked={cursosSelected.indexOf(curso.id) !== -1}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                                        <label className="w-full py-3 ml-2 text-sm font-medium text-gray-900">{curso.nombre}</label>
                                    </div>
                                </li>
                            ))}

                        </ul>
                    </div>

                    <div className="flex-auto w-full mb-1 text-xs space-y-2 mt-4">
                        <h3 className="mb-4 font-semibold text-gray-900 ">Etiquetas</h3>
                        <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
                            {etiquetasOptions.map((etiqueta) => {
                                    return (
                                        <li className="w-full border-b border-gray-200 rounded-t-lg" key={etiqueta.id}>
                                            <div className="flex items-center pl-3">
                                                <input
                                                    onChange={() => changeEtiqueta(etiqueta.id)}
                                                    id={etiqueta.id}
                                                    checked={etiquetasSelected.indexOf(etiqueta.id) !== -1}
                                                    type="checkbox"
                                                    value=""
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                                />
                                                <label className="w-full py-3 ml-2 text-sm font-medium text-gray-900">{etiqueta.nombre}</label>
                                            </div>
                                        </li>
                                    );
                            })}

                        </ul>
                    </div>
                    <div className="flex-auto w-full mb-1 text-xs space-y-2">
                        <label className="font-semibold text-gray-600 py-2">Porque es la respuesta correcta</label>
                        <textarea
                            id="explicacion"
                            name="explicacion"
                            value={pregunta.explicacion}
                            onChange={handleChange}
                            className="min-h-[100px] max-h-[300px] h-28 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg  py-4 px-4"
                            placeholder="Descripción de la pregunta" />
                    </div>
                    <div className="flex-auto w-full mb-1 text-xs space-y-2 ">

                        <Alternativas type={pregunta.tipo} onAlternativasChange={handleAlternativasChange} />

                    </div>

                    <form>
                        <div className="mt-5 text-right md:space-x-3 md:block flex flex-col-reverse">
                            <button onClick={handleCancel} className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-red-400 hover:text-white">Cancelar</button>
                            <button onClick={handleSubmit} type="submit" className="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-500">Guardar</button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default FormQuestion;