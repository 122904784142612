import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BarChart from "./BarChart";
import TrueFalseQuestion from "../../components/QuestionFormat/TrueFalseQuestion";
import RelationalChoice from "../../components/QuestionFormat/RelationalChoice";
import OrderQuestion from "../../components/QuestionFormat/OrderQuestion";
import MultipleChoice from "../../components/QuestionFormat/MultipleChoice";
import BoxesQuestion from "../../components/QuestionFormat/BoxesQuestion";

import EtiquetasService from "../../services/EtiquetasService";
import { EtiquetaDictInterface } from "../../models/EtiquetasModel";
import { TiposPreguntas } from "../../models/PreguntasModel";

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import PromedioGeneralChart from "./PromedioGeneralChart";

import ExamenesService from "../../services/ExamenesService";

interface PreguntasNota {
    [etiqueta: string]: number;
}
interface PreguntasEtiqueta {
    [tipo: string]: PreguntasNota;
}

interface Data {
    preguntas_etiqueta: PreguntasEtiqueta;
    total_general: number;
    soluciones: QuestionnaireProps;
}

interface DataChart {
    etiquetas: string[];
    correctas: number[];
    incorrectas: number[];
}

interface QuestionnaireProps {
    id: string;
    nombre: string;
    preguntas: Pregunta[];
    tipo: string;
    respuestas?: Answer[];
}
interface Answer {
    id: string;
    solucion: Solution[];
    pregunta: string;
    correcto: boolean;
}
interface Solution {
    id: string;
    peso: number;
    alternativa?: string;
}
interface Pregunta {
    id: string;
    texto: string;
    tipo: string;
    etiquetas: string[];
    alternativas: Alternativa[] | Alternativa[][];
    complete?: boolean;
    explicacion?: string;
}
interface Alternativa {
    id: string;
    texto: string;
    peso: number;
}
interface ResultadosExamen {
    fecha: Date;
    id: string;
    nombre: string;
    puntaje: number;
    puntaje_maximo: number;
}

const Results: React.FC = () => {

    const [result, setResult] = useState<Data>();
    const [visible, setVisible] = useState<boolean>(false);
    const [preguntaSelected, setPreguntaSelected] = useState<Pregunta>();
    const [respuestaSelected, setRespuestaSelected] = useState<Answer>();
    const [etiquetas, setEtiquetas] = useState<EtiquetaDictInterface>();
    const [showPromedioGeneral, setShowPromedioGeneral] = useState(false);

    const [dataChart, setDataChart] = useState<DataChart>({ 'etiquetas': [], 'correctas': [], 'incorrectas': [] });
    const [showQuestions, setShowQuestions] = useState(false);
    const { examenEnRuta } = useParams();

    const preguntasCorrectas: Pregunta[] = [];
    const preguntasIncorrectas: Pregunta[] = [];
    const [promedioExamenes, setPromedioExamenes] = useState<Number>();

    const navigate = useNavigate()

    const tiposPreguntas = TiposPreguntas;
    const Idcourse = sessionStorage.getItem("selectedCourse")

    useEffect(() => {
        ExamenesService.getResultadoExamen(examenEnRuta ?? '',String(Idcourse)).then((data) => setResult(data));
        ExamenesService.getHistorialExamenes(Idcourse ?? '',String(Idcourse)).then((data) => {
            let puntajeTotal = 0;
            let cantidad = 0;
            data.map((item: ResultadosExamen) => {
                if (item.puntaje_maximo != 0) {
                    puntajeTotal += (item.puntaje / item.puntaje_maximo) * 100;
                    cantidad += 1;
                }
            })
            setPromedioExamenes(Number((puntajeTotal / cantidad).toFixed(2)))
        });
        EtiquetasService.getEtiquetasDict().then((data) => setEtiquetas(data));

    }, []);

    const handleCancel = () => {
        navigate(`/curso/${sessionStorage.getItem('selectedCourse')}`);
    };

    const cambiarResultado = (tema: { [key: string]: number }) => {
        const keys = Object.keys(tema);
        const values: number[] = [];
        for (const key in tema) {
            if (tema.hasOwnProperty(key)) {
                const decimalValue = tema[key];
                const percentage = Number((decimalValue).toFixed(2));
                values.push(percentage);
            }
        }
        
        const invertedValues = values.map(value => Number((100 - value).toFixed(2)));
        setDataChart({ 'etiquetas': keys, 'correctas': values, 'incorrectas': invertedValues });
    };

    const getRespuestaPregunta = (id: string): Answer | undefined => {
        return result?.soluciones.respuestas?.find(respuesta => respuesta.pregunta === id)
    }

    const getPregunta = (quetionSelected: Pregunta, respuesta: Answer | undefined) => {
        try {
            if (quetionSelected.tipo === "Verdadero" || quetionSelected.tipo === "Falso") {
                return <>
                    <TrueFalseQuestion
                        editable={false}
                        correcto={quetionSelected.tipo === "Verdadero" ? "V" : "F"}
                        respuestas={respuesta}
                        texto={quetionSelected.texto}
                        id={quetionSelected.id}
                        options={[{ "V": "Verdadero" }, { "F": "Falso" }]} />

                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>

                </>
            }
            if (quetionSelected.tipo === "Agrupamiento") {
                return <>
                    <RelationalChoice
                        editable={false}
                        id={quetionSelected.id}
                        respuestas={getRespuestaPregunta(quetionSelected.id)}
                        texto={quetionSelected.texto}
                        options={quetionSelected.alternativas as Alternativa[][]} />
                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>
                </>
            }
            if (quetionSelected.tipo === "Cajas") {
                return <><BoxesQuestion
                    editable={false}
                    id={quetionSelected.id}
                    respuestas={getRespuestaPregunta(quetionSelected.id)}
                    texto={quetionSelected.texto}
                    options={quetionSelected.alternativas as Alternativa[][]} />
                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>
                </>
            }
            if (quetionSelected.tipo === "Opcion multiple") {
                const optionsMultipleChoise = quetionSelected.alternativas.map((alternativa) => ({
                    [(alternativa as Alternativa).id]: (alternativa as Alternativa).texto,
                    peso: (alternativa as Alternativa).peso
                }))
                return <><MultipleChoice
                    editable={false}
                    respuestas={getRespuestaPregunta(quetionSelected.id)}
                    texto={quetionSelected.texto}
                    id={quetionSelected.id}
                    options={optionsMultipleChoise} />
                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>
                </>
            }
            if (quetionSelected.tipo === "Ordenamiento") {
                const optionsOrder = quetionSelected.alternativas.map((alternativa) => ({
                    [(alternativa as Alternativa).id]: (alternativa as Alternativa).texto,
                }))
                return <><OrderQuestion
                    editable={false}
                    respuestas={getRespuestaPregunta(quetionSelected.id)}
                    texto={quetionSelected.texto}
                    id={quetionSelected.id}
                    options={optionsOrder} />
                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>
                </>
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelect = (pregunta: Pregunta, respuesta?: Answer) => {
        setPreguntaSelected(pregunta);
        setRespuestaSelected(respuesta);
        setVisible(true);
    }

    // Llenar los arrays con las preguntas correctas e incorrectas
    if (result && result.soluciones.preguntas) {
        result.soluciones.preguntas.forEach((pregunta) => {
            const respuesta = getRespuestaPregunta(pregunta.id);
            if (respuesta && respuesta.correcto) {
                preguntasCorrectas.push(pregunta);
            } else {
                preguntasIncorrectas.push(pregunta);
            }
        });
    }
    return (

        <div className="flex flex-col items-center min-h-screen bg-neutral-100 shadow-md rounded-md" >
            <div className="w-5/6 bg-white text-black text-center text-xl italic p-4 rounded-md my-2 mx-4"></div>

            <div className="w-full md:flex md:justify-between">
                <div className="md:w-1/5 w-full p-6 bg-white sm:h-[calc(86vh-3rem)]  shadow-md rounded-md mx-4 md:mb-0 mb-4">
                    {/* Lado Izquierdo */}
                    <div className="bg-amber-400 text-white p-4 rounded-md mb-4">RESULTADOS ESTUDIO</div>
                    {/* Botón de Cancelar */}
                    <div className="py-2  ">
                        <div className="overflow-y-auto max-h-[calc(50vh-3rem)] grid grid-cols-1 divide-y text-sky-500 p-2">
                            <div onClick={() => {
                                setShowQuestions(false);
                                setShowPromedioGeneral(false);
                                cambiarResultado({ 'total_general': Number(result?.total_general.toFixed(2)) || 0 })
                            }
                            }
                                className="cursor-pointer hover:text-yellow-500">Resultado General</div>
                            {Object.entries(result?.preguntas_etiqueta || {}).map(([etiqueta, valores]) => (
                                <div key={etiqueta}
                                    onClick={() => {
                                        cambiarResultado(valores);
                                        setShowQuestions(false);
                                        setShowPromedioGeneral(false);

                                    }}
                                    className="cursor-pointer hover:text-yellow-500">{etiqueta}</div>
                            ))}
                            <div onClick={() => {
                                setShowQuestions(false);
                                setShowPromedioGeneral(true);
                            }
                            }
                                className="cursor-pointer hover:text-yellow-500">Promedio General</div>
                            <div
                                onClick={() => {
                                    setShowQuestions(true);
                                }}
                                className="cursor-pointer hover:text-yellow-500"
                            >
                                Respuestas
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={handleCancel}
                        className="bg-red-500 text-white px-4 mt-4 py-2 rounded-md"
                    >
                        Atras
                    </button>

                </div>

                <div className="md:w-3/3 w-full sm:h-full p-4 bg-white shadow-md rounded-md overflow-y-auto no-scrollbar">
                    {/* Graficos */}
                    {!showQuestions && (
                        <div className="drop-shadow-lg overflow-y-auto sm:h-[calc(81vh-3rem)]">
                            {showPromedioGeneral ? (
                                // Muestra la gráfica de Promedio General
                                <PromedioGeneralChart promedio={[Number(promedioExamenes)]} examenActual={[Number(result?.total_general)]} />
                            ) : (
                                // Muestra la gráfica de resultados generales
                                <BarChart correctas={dataChart.correctas} incorrectas={dataChart.incorrectas} etiquetas={dataChart.etiquetas} />
                            )}
                        </div>
                    )}

                    {showQuestions &&
                        <div className="card">
                            <Dialog header="Detalle" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                                {preguntaSelected !== undefined && getPregunta(preguntaSelected, respuestaSelected)}
                            </Dialog>
                            <Accordion activeIndex={0}>
                                <AccordionTab header="Preguntas correctas">
                                    {preguntasCorrectas.map((pregunta, index) => {
                                        const respuesta = getRespuestaPregunta(pregunta.id);
                                        const texto = respuesta !== undefined ? (respuesta.correcto ? 'Correcto' : 'Incorrecto') : 'No hay respuesta';
                                        return (
                                            <div onClick={() => handleSelect(pregunta, respuesta)} className={`shadow-md rounded-md p-2 my-4 bg-slate-50 cursor-pointer`} key={index}>
                                                <div className="flex justify-between">
                                                    <div className="text-xl font-semibold">Pregunta {index + 1}</div>
                                                    <Tag className="mr-2" icon="pi pi-info-circle" severity="info" value={`${tiposPreguntas[pregunta.tipo][0]}`}></Tag>
                                                    <Tag className="mr-2" icon="pi pi-info-circle" severity="success" value={`Eiqueta 1`}></Tag>
                                                    <Tag className="mr-2" icon="pi pi-info-circle" severity="success" value={`Eiqueta 2`}></Tag>
                                                    <div className="text-xl font-semibold">{texto}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </AccordionTab>
                                <AccordionTab header="Preguntas incorrectas">
                                    {preguntasIncorrectas.map((pregunta, index) => {
                                        const respuesta = getRespuestaPregunta(pregunta.id);
                                        const texto = respuesta !== undefined ? (respuesta.correcto ? 'Correcto' : 'Incorrecto') : 'No hay respuesta';
                                        return (
                                            <div onClick={() => handleSelect(pregunta, respuesta)} className={`shadow-md rounded-md p-2 my-2 cursor-pointer`} key={index}>
                                                <div className="flex justify-between">
                                                    <div className="text-xl font-semibold ">Pregunta {index + 1}</div>
                                                    <Tag className="mr-2" icon="pi pi-info-circle" severity="info" value={`${tiposPreguntas[pregunta.tipo][0]}`}></Tag>
                                                    <Tag className="mr-2" icon="pi pi-info-circle" severity="success" value={`Eiqueta 1`}></Tag>
                                                    <Tag className="mr-2" icon="pi pi-info-circle" severity="success" value={`Eiqueta 2`}></Tag>
                                                    <div className="text-xl font-semibold">{texto}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </AccordionTab>
                            </Accordion>
                        </div>
                    }
                </div>
            </div>
        </div>

    );

};

export default Results;

