import axios from "axios";
import { useState, useEffect } from "react";
import { API } from "../../services/api";
import ExamenesService from "../../services/ExamenesService";
import {ListItem, Answer, Solution} from "../../models/PreguntasModel";

export interface BoxChoiceProps {
    id: string;
    editable: boolean;
    texto: string;
    options: ListItem[][];
    examen?: string;
    respuestas?: Answer;
    handlePost?: (respuesta: any, examencod: string) => void;
    onButtonClick?: (id: string) => void;
    next?: () => void;
}

const BoxesQuestion: React.FC<BoxChoiceProps> = ({ id, texto,next, respuestas, options, examen = '', onButtonClick, handlePost, editable }) => {

    const [list, setList] = useState<ListItem[]>([]);
    const [secondList, setSecondList] = useState<ListItem[]>([]);
    const [draggedList, setDraggedList] = useState<ListItem[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const [allItemsDragged, setAllItemsDragged] = useState(false);
    const [numberOfColumns, setNumberOfColumns] = useState(3); // Cambia el valor según sea necesario
    const [columnWeights, setColumnWeights] = useState<number[]>(Array(numberOfColumns).fill(0));

    const [draggedLists, setDraggedLists] = useState<Map<number, ListItem[]>>(new Map());

    useEffect(() => {
        setList(options[0].sort(() => Math.random() - 0.5));
        setSecondList(options[1])
        setNumberOfColumns(options[1].length)
        setDraggedLists(new Map())
        if (respuestas) {
            // Crear un mapa para almacenar las relaciones entre los pesos y los índices
            const pesoToIndexMap = new Map();
            options[0].forEach((idAlter) => {
                pesoToIndexMap.set(idAlter.id, { idAlter, texto: idAlter.texto });
            });

            console.log(pesoToIndexMap);

            respuestas.solucion.forEach((item) => {
                const relacion = pesoToIndexMap.get(item.alternativa);

                if (relacion && item.peso < 100) {
                    // Comprobar si el peso es menor que 100 antes de agregarlo a draggedLists
                    setDraggedLists((prevDraggedLists) => {
                        const clonedDraggedLists = new Map(prevDraggedLists);

                        let sourceListIndex = -1; // Usar el índice del mapa
                        options[1].forEach((option, index) => {
                            if (option.peso === item.peso * 100) {
                                sourceListIndex = index;
                            }
                        });

                        console.log(options)

                        // Verificar si ya existe una lista con esta clave, si no, crear una nueva lista vacía
                        if (!clonedDraggedLists.has(sourceListIndex)) {
                            clonedDraggedLists.set(sourceListIndex, []);
                        }

                        // Crear el objeto deseado
                        const newItem = {
                            id: String(item.alternativa),
                            texto: String(relacion.texto),
                            peso: item.peso,
                        };

                        // Verificar si el elemento ya existe en la lista correspondiente antes de agregarlo
                        const existingItems = clonedDraggedLists.get(sourceListIndex) || [];
                        if (!existingItems.some((draggedItem) => draggedItem.id === newItem.id)) {
                            existingItems.push(newItem);
                            clonedDraggedLists.set(sourceListIndex, existingItems);
                            setList((prevList) => {
                                // Filtra la lista previa para eliminar elementos con el mismo ID que newItem
                                const filteredList = prevList.filter((item) => {
                                    return item.id !== newItem.id;
                                });
                                return filteredList;
                            });
                        }

                        return clonedDraggedLists;
                    });
                }
            });
            console.log(options[0])
        }


        console.log(secondList)
    }, [id, examen,options]);

    const handleDragOver = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setIsDragging(editable);
    }
    const handleDragStart = (event: React.DragEvent<HTMLLIElement>) => {
        event.dataTransfer.setData("id", event.currentTarget.id);
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, containerIndex: number) => {
        event.preventDefault();
        const id = event.dataTransfer.getData("id");
        const item = list.find(x => x.id === id);

        if (item) {
            const texto = item.texto;
            const sourceContainerIndex = Array.from(draggedLists.keys()).find(index => draggedLists.get(index)?.some(item => item.id === id));
            const updatedLists = new Map(draggedLists);
            const containerList = updatedLists.get(containerIndex) || [];

            // Agregar el elemento al nuevo contenedor con el texto correspondiente
            containerList.push({ id, texto });
            updatedLists.set(containerIndex, containerList);

            if (sourceContainerIndex !== undefined) {
                // Si el elemento proviene de otro contenedor, eliminarlo de ese contenedor
                const sourceContainerList = updatedLists.get(sourceContainerIndex);
                if (sourceContainerList) {
                    const updatedSourceList = sourceContainerList.filter(item => item.id !== id);
                    updatedLists.set(sourceContainerIndex, updatedSourceList);
                }
            }

            // Actualizar el peso de la columna en la que se soltó el elemento
            const updatedColumnWeights = [...columnWeights];
            updatedColumnWeights[containerIndex] += 1; // Puedes ajustar el valor del peso según tus necesidades
            setColumnWeights(updatedColumnWeights);

            // Eliminar el elemento de la lista principal
            const updatedList = list.filter(item => item.id !== id);
            setList(updatedList);

            setIsDragging(false);
            setDraggedLists(updatedLists);

            if (updatedList.length === 0) {
                // Si no quedan elementos en la lista principal, establece allItemsDragged en true.
                setAllItemsDragged(editable);
            }
        } else {
            // Si el elemento ya estaba en un contenedor y se movió, simplemente actualiza la ubicación
            const sourceContainerIndex = Array.from(draggedLists.keys()).find(index => draggedLists.get(index)?.some(item => item.id === id));
            if (sourceContainerIndex !== undefined && sourceContainerIndex !== containerIndex) {
                const updatedLists = new Map(draggedLists);
                const sourceContainerList = updatedLists.get(sourceContainerIndex);

                if (sourceContainerList) {
                    // Elimina el elemento del contenedor de origen
                    const updatedSourceList = sourceContainerList.filter(item => item.id !== id);
                    updatedLists.set(sourceContainerIndex, updatedSourceList);

                    // Agrega el elemento al contenedor de destino con el texto correspondiente
                    const destinationContainerList = updatedLists.get(containerIndex) || [];
                    const draggedItem = sourceContainerList.find(item => item.id === id);
                    if (draggedItem) {
                        destinationContainerList.push({ id, texto: draggedItem.texto });
                        updatedLists.set(containerIndex, destinationContainerList);
                    }

                    setDraggedLists(updatedLists);
                }
            }
        }
    }



    const handleSaveButtonClick = () => {

        const secondListIds = secondList.map(item => item.id);

        // Recopilar las respuestas en el formato necesario
        const selectedItems = Array.from(draggedLists.values()).flat();

        // Crear un objeto para rastrear el peso de cada columna
        const columnWeights: { [key: number]: number } = {};

        // Inicializar el peso de cada columna en 0
        for (let i = 0; i < numberOfColumns; i++) {
            columnWeights[i] = 0;
        }

        selectedItems.forEach((item) => {
            // Buscar en qué columna se encuentra el elemento
            Array.from(draggedLists.entries()).forEach(([index, columnList]) => {
                if (columnList.some(listItem => listItem.id === item.id)) {
                    // Incrementar el peso de la columna correspondiente
                    columnWeights[index] += 1; // Puedes ajustar el valor del peso según tus necesidades
                }
            });
        });

        let combinedData: { alternativa: string; peso: number | undefined; texto: string; }[] = [];
        secondList.map((item, index) => {
            const draggedItem = draggedLists.get(index);

            if (draggedItem) {
                draggedItem.map((drag, i) => {

                    combinedData.push({
                        alternativa: drag.id,
                        peso: Number(item.peso) / 100,
                        texto: drag.texto,
                    });

                });
                combinedData.push({
                    alternativa: item.id,
                    peso: item.peso,
                    texto: item.texto,
                });

            } else {
                combinedData.push({
                    alternativa: item.id,
                    peso: item.peso,
                    texto: item.texto,
                });
            }
        });

        // Construir la respuesta
        const respuesta = {
            "solucion": combinedData,
            "pregunta": id,
        };

        // Realizar la solicitud PATCH para guardar la respuesta
        console.log(respuesta)
        ExamenesService.patchExamen(examen, respuesta,String(sessionStorage.getItem("selectedCourse")))
            .then((data) => {
                console.log("Respuesta guardada correctamente");
                console.log(data);
            })
            .catch((error) => {
                console.error("Error al guardar la respuesta:", error);
            });
        onButtonClick?.(id);
        if (next){
            next();
        }
    };

    return (

        <>
            <p>{texto}</p>
            <div className="grid grid-rows-2 gap1">
                <div className="p-4 mt-2 bg-white rounded-lg shadow-lg">
                    <ul className="list-none p-0 m-0 min-h-40">

                        {list.map(item => {
                            return (
                                <li className=" 
                                transition ease-in-out 
                                delay-150 
                                hover:-translate-y-1 
                                hover:scale-105 
                                bg-white border border-yellow-300 p-2  mb-2 cursor-move
                                text-center"
                                    key={item.id} id={item.id} draggable={editable} onDragStart={handleDragStart}>{item.texto}</li>
                            );
                        })}
                        <div className="flex justify-center mt-4">

                            {allItemsDragged && (
                                <>
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={() => {
                                            // Restablecer la lista principal (list) con los elementos del primer array
                                            setList(options[0]);

                                            // Restablecer los estados relacionados con los elementos arrastrados
                                            setDraggedLists(new Map());
                                            setDraggedList([]);
                                            setAllItemsDragged(false);
                                        }}
                                    >
                                        Reiniciar
                                    </button>
                                </>
                            )}
                        </div>
                    </ul>
                </div>
                <div className="grid gap-3 grid-cols-3">

                    {Array.from({ length: numberOfColumns }, (_, index) => (
                        <div
                            key={index}
                            className="mt-4 p-4 bg-white rounded-lg shadow-lg border-dashed border-2 min-h-60"
                            onDragOver={handleDragOver}
                            onDrop={(event) => handleDrop(event, index)}
                        >
                            <p className="border-b-4 border-amber-200 mb-2  text-center">{secondList[index] && secondList[index].texto ? String(secondList[index].texto) : 'Error en la subida de pregunta'}</p>
                            <ul className="list-none p-0 m-0 bg-yellow-500 hover:bg-yellow-300  border border-gray-100 min-h-40">
                                {draggedLists.get(index)?.map((item) => (
                                    <li
                                        className="
                                    
                                        transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110  
                                        bg-white border border-yellow-300
                                        p4 mb-2 cursor-move text-center
                                        text-sm md:text-base lg:text-lg
                                    
                                        "
                                        key={item.id}
                                        id={item.id}
                                        draggable={editable}
                                        onDragStart={handleDragStart}
                                    >
                                        {item.texto}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                {editable ?
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 mt-4 px-4 rounded"
                        onClick={handleSaveButtonClick}
                    >
                        Siguiente
                    </button>
                    :
                    <></>
                }
            </div>


        </>

    );

}; export default BoxesQuestion;