import React, { useState } from 'react';

import Swal from 'sweetalert2';
import ipc from '../../assets/Header/ipc.png';
import { API_SIMPLE } from '../../services/api';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Button } from 'primereact/button';

import CursosService from '../../services/CursosService';

interface PropsUnitCourse {
    id: string;
    name: string;
    row: number;
    image: string
}


export default function UnitCourseWithourRegistering(props: PropsUnitCourse) {

    function Color(row: number) {
        if (row === 1) {
            return ("bg-[#fbca99]")
        }
        if (row === 2) {
            return ("bg-[#add2bc]")
        }
        if (row === 3) {
            return ("bg-[#dfe99f]")
        }
    }

    /*function linkToCourse() {
    }*/

    const handleCourseAlert = (id: string) => {
        Swal.fire({
            title: 'Curso bloquedo',
            text: 'No estas inscrito en este curso.',
            icon: 'info',
            confirmButtonText: 'Ok',
            showDenyButton: true,
            denyButtonText: 'Inscribirme'
        }).then((result) => {
            if (result.isDenied) {
                console.log(id)
                CursosService.postMatriculaUser(id).then(data => {
                    window.location.reload();
                });
            }
        });
    }

    let link_image = ''

    if (props.image === undefined || props.image === null) {
        link_image = ipc
    } else {
        link_image = props.image
    }

    return (
        <>
            <button onClick={() => handleCourseAlert(props.id)}>
                <div className={`group opacity-100 brightness-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:md:scale-110 max-w-md mx-auto  rounded-xl shadow-md overflow-hidden md:max-w-2xl ${Color(props.row)} `}>
                    <div className="md:flex">
                        <div className="md:shrink-0">
                            <img className="h-36 w-full md:max-w-full object-cover md:h-36" src={link_image} alt="Modern building architecture" />
                        </div>
                        <div className="p-8 pl-2">
                            <div className="uppercase group-hover:text-white  text-slate-100 border-b-2  group-hover:border-white border-gray-200 font-semibold leading-5 text-foreground/90">{props.name}</div>
                            <p className="group-hover:text-white font-semibold leading-5 text-small text-foreground/80">Descripcion del curso</p>

                        </div>
                    </div>

                </div>
            </button>
        </>
    );
}