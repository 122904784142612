import api from './api';

class PreguntasService {

  public getPreguntas() {
    return api.get(`preguntas/`)
      .then(response => response.data)
      .catch(error => {
        console.log(error);
      });
  };

  public getPreguntasById(preguntaId: string) {
    return api.get(`preguntas/${preguntaId}`)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  public getPreguntasCount(cursoID: string) {
    return api.get(`contador/${cursoID}`)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }


  public postPregunta(pregunta: any) {
    return api.post(`preguntas/`, pregunta)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  public putPregunta(id: string, pregunta: any,) {
    return api.put(`preguntas/${id}`, pregunta)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  public deletePregunta(id: string) {
    return api.delete(`preguntas/${id}`)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

};

export default new PreguntasService();
