import React, { useState, useEffect } from "react";

import axios from "axios";
import { API } from "../../../services/api";

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import TrueFalseQuestion from "../../../components/QuestionFormat/TrueFalseQuestion";
import RelationalChoice from "../../../components/QuestionFormat/RelationalChoice";
import BoxesQuestion from "../../../components/QuestionFormat/BoxesQuestion";
import MultipleChoice from "../../../components/QuestionFormat/MultipleChoice";
import OrderQuestion from "../../../components/QuestionFormat/OrderQuestion";

import PreguntasService from "../../../services/PreguntasService";

interface propsPreguntaDetail {
    id: string;
    visible: boolean;
    setVisible: (activo: boolean) => void;
}
interface Answer {
    id: string;
    solucion: Solution[];
    pregunta: string;
    correcto: boolean;
}
interface Solution {
    id: string;
    peso: number;
    alternativa?: string;
}
interface Pregunta {
    id: string;
    texto: string;
    tipo: string;
    etiquetas: string[];
    alternativas: Alternativa[] | Alternativa[][];
    complete?: boolean;
    explicacion?: string;
}
interface Alternativa {
    id: string;
    texto: string;
    peso: number;
}

const PreguntaDetail: React.FC<propsPreguntaDetail> = ({ id, visible, setVisible }) => {
    const [pregunta, setPregunta] = useState<Pregunta>({
        "id": "",
        "texto": "",
        "tipo": "",
        "etiquetas": [],
        "alternativas": [],
        "explicacion": ""
    });

    useEffect(() => {
        if (id) {
            PreguntasService.getPreguntasById(id).then((data) => setPregunta(data));
        }
    }, [id]);

    const getPregunta = (quetionSelected: Pregunta) => {
        try {
            if (quetionSelected.tipo === "Verdadero" || quetionSelected.tipo === "Falso") {
                return <>
                    <TrueFalseQuestion
                        editable={false}
                        correcto={quetionSelected.tipo === "Verdadero" ? "V" : "F"}
                        texto={quetionSelected.texto}
                        id={quetionSelected.id}
                        options={[{ "V": "Verdadero" }, { "F": "Falso" }]} />

                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>

                </>
            }
            if (quetionSelected.tipo === "Agrupamiento") {
                return <>
                    <RelationalChoice
                        editable={false}
                        id={quetionSelected.id}
                        texto={quetionSelected.texto}
                        options={quetionSelected.alternativas as Alternativa[][]} />
                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>
                </>
            }
            if (quetionSelected.tipo === "Cajas") {
                return <><BoxesQuestion
                    editable={false}
                    id={quetionSelected.id}
                    texto={quetionSelected.texto}
                    options={quetionSelected.alternativas as Alternativa[][]} />
                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>
                </>
            }
            if (quetionSelected.tipo === "Opcion multiple") {
                const optionsMultipleChoise = quetionSelected.alternativas.map((alternativa) => ({
                    [(alternativa as Alternativa).id]: (alternativa as Alternativa).texto,
                    peso: (alternativa as Alternativa).peso
                }))
                return <><MultipleChoice
                    editable={false}
                    texto={quetionSelected.texto}
                    id={quetionSelected.id}
                    options={optionsMultipleChoise} />
                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>
                </>
            }
            if (quetionSelected.tipo === "Ordenamiento") {
                const optionsOrder = quetionSelected.alternativas.map((alternativa) => ({
                    [(alternativa as Alternativa).id]: (alternativa as Alternativa).texto,
                }))
                return <><OrderQuestion
                    editable={false}
                    texto={quetionSelected.texto}
                    id={quetionSelected.id}
                    options={optionsOrder} />
                    <div className="
                        bg-green-500 rounded-lg
                         p-4 text-white hover:bg-green-600
                         transition ease-in-out 
                         delay-150 
                         hover:-translate-y-1 
                         ">
                        <p className="border-b-2 ">Porque es la respuesta correcta:</p>
                        <div className="p-2">
                            {quetionSelected.explicacion}
                        </div>
                    </div>
                </>
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="card flex justify-content-center">
            <Dialog header="Detalle" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                {getPregunta(pregunta)}
            </Dialog>
        </div>
    )

};
export default PreguntaDetail;