import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Fieldset } from 'primereact/fieldset';
import PreguntasTable from '../Tables/PreguntasTable';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { MenuItem } from 'primereact/menuitem';
import UsersTable from '../Tables/UsersTable';
import MatriculasTable from '../Tables/MatriculasTable';
import EtiquetasTable from '../Tables/EtiquetasTable';
import CursoForm from '../CompAdmin/Forms/CursoForm';
import EtiquetasForm from '../CompAdmin/Forms/EtiquetasForm';
import FormQuestion from '../CompAdmin/Questions/FormQuestion';
import CursosTable from '../Tables/CursosTable';

const AdminMain: React.FC = () => {

    const items = [
        { label: 'Preguntas', icon: 'pi pi-fw pi-table' },
        { label: 'Usuarios', icon: 'pi pi-fw pi-users' },
        { label: 'Matriculas', icon: 'pi pi-fw pi-pencil' },
        { label: 'Tipo de examenes', icon: 'pi pi-fw pi-tags' },
        { label: 'Cursos', icon: 'pi pi-fw pi-book' }

    ];

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const renderOption = () => {
        switch (activeIndex) {
            case 0:
                return <PreguntasTable />;
            case 1:
                return <UsersTable />;
            case 2:
                return <MatriculasTable />;
            case 3:
                return <EtiquetasTable />;
            case 4:
                return <CursosTable />;
            default:
                return <PreguntasTable />;
        }
    }

    console.log(activeIndex)
    return (
        <>
            <div className="class">
                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                {renderOption()}
            </div>


        </>
    );
};

export default AdminMain;
