import { useEffect, useState } from "react";

interface AlternativaProps {
    type: string;
    onAlternativasChange: (nuevasAlternativas: {
        id: string;
        texto: string;
        peso: number;
    }[]) => void;
}

const Alternativas: React.FC<AlternativaProps> = ({ type, onAlternativasChange }) => {
    const [alternativas, setAlternativas] = useState<{ id: string; texto: string, peso: number }[]>([]);
    const [alternativasGrupo, setAlternativasGrupo] = useState<{ id: string; texto: string, peso: number }[][]>([]);
    const [alternativaCorrecta, setAlternativaCorrecta] = useState<{ id: string; texto: string, peso: number } | null>(
        null
    );

    useEffect(() => {
        // Reiniciar las alternativas cuando cambia el tipo
        setAlternativas([]);
        setAlternativaCorrecta(null);
        onAlternativasChange([])
    }, [type]);

    const handleAgregarAlternativaMultiChoise = () => {
        // Agregar una nueva alternativa con un ID único
        const nuevaAlternativa = {
            id: Date.now().toString(), // Usar una marca de tiempo como ID único
            texto: "Nueva alternativa",
            peso: 0,
        };
        setAlternativas([...alternativas, nuevaAlternativa]);
    };

    const handleAgregarAlternativaGrupo = () => {
        // Agregar una nueva alternativa con un ID único
        const peso = alternativasGrupo.length + 1
        const nuevaAlternativa = [{
            id: '',
            texto: "Nueva alternativa" + (peso * 100),
            peso: peso * 100,
        }, {
            id: '',
            texto: "Nueva alternativa" + peso,
            peso: peso,
        }];
        setAlternativasGrupo([...alternativasGrupo, nuevaAlternativa]);
    };

    const handleAgregarSubAlternativaGrupo = (index: number) => {

        const nuevasAlternativas = [...alternativasGrupo];

        const nuevaAlternativa = {
            id: '',
            texto: "Nueva alternativa" + (index + 1),
            peso: index + 1,
        };

        nuevasAlternativas[index].push(nuevaAlternativa);

        setAlternativasGrupo(nuevasAlternativas);
    };

    const handleAgregarAlternativaOrder = () => {
        const nuevoPeso = alternativas.length + 1;
        // Agregar una nueva alternativa con un ID único
        const nuevaAlternativa = {
            id: Date.now().toString(), // Usar una marca de tiempo como ID único
            texto: "Nueva alternativa",
            peso: nuevoPeso,
        };
        setAlternativas([...alternativas, nuevaAlternativa]);
        onAlternativasChange([...alternativas, nuevaAlternativa]);
    };

    const handleSeleccionarCorrecta = (alternativaSelect: { id: string; texto: string, peso: number }) => {
        // Establecer la alternativa seleccionada como correcta
        setAlternativaCorrecta(alternativaSelect);
        const nuevasAlternativas = alternativas.map((alternativa) => ({
            ...alternativa,
            peso: alternativa.texto === alternativaSelect.texto ? 1 : 0,
        }));
        setAlternativas(nuevasAlternativas);
        onAlternativasChange(nuevasAlternativas);
    };

    const handleBorrarAlternativa = (alternativaId: string) => {
        // Eliminar la alternativa con el ID proporcionado
        const nuevasAlternativas = alternativas.filter(
            (alternativa) => alternativa.id !== alternativaId
        );
        setAlternativas(nuevasAlternativas);

        // Si la alternativa eliminada era la correcta, borrar la selección correcta
        if (alternativaCorrecta?.id === alternativaId) {
            setAlternativaCorrecta(null);
        }
        onAlternativasChange(nuevasAlternativas);
    };
    const handleBorrarAlternativaGrupo = (index: number) => {
        const nuevasAlternativas = [...alternativasGrupo];

        nuevasAlternativas.splice(index, 1);

        const alternativasActualizadas = nuevasAlternativas.map((subArray, index) => {
            const primerObjeto = { ...subArray[0], peso: (index + 1) * 100 };
            const segundoObjeto = { ...subArray[1], peso: index + 1 };
            return [primerObjeto, segundoObjeto];
        });

        setAlternativasGrupo(alternativasActualizadas);

        onAlternativasChange(alternativasActualizadas.flat());
    };

    const handleBorrarAlternativaOrder = (id: string) => {
        // Eliminar la alternativa con el ID proporcionado
        const nuevasAlternativas = alternativas.filter((alternativa) => alternativa.id !== id);

        // Actualizar los pesos de las alternativas restantes
        const alternativasActualizadas = nuevasAlternativas.map((alternativa, index) => ({
            ...alternativa,
            peso: index + 1,
        }));

        // Establecer las alternativas actualizadas en el estado
        setAlternativas(alternativasActualizadas);
        onAlternativasChange(alternativasActualizadas);
    };


    const handleCambiarTextoAlternativa = (
        alternativaId: string,
        nuevoTexto: string
    ) => {
        // Actualizar el texto de la alternativa con el ID proporcionado
        const nuevasAlternativas = alternativas.map((alternativa) =>
            alternativa.id === alternativaId
                ? { ...alternativa, texto: nuevoTexto }
                : alternativa
        );
        setAlternativas(nuevasAlternativas);

        // Llamar a la función de devolución de llamada para enviar las alternativas actualizadas
        onAlternativasChange(nuevasAlternativas);
    };

    const handleCambiarTextoAlternativaGrupo = (
        index: number,
        nuevoTexto: string,
        orden: number,
    ) => {
        const nuevasAlternativas = [...alternativasGrupo];
        const nuevaAlternativa = { ...nuevasAlternativas[index][orden] };

        nuevaAlternativa.texto = nuevoTexto;
        nuevasAlternativas[index][orden] = nuevaAlternativa;

        setAlternativasGrupo(nuevasAlternativas);
        console.log("nuevas Alternativas")
        console.log(nuevasAlternativas.reduce((acumulador, grupo) => {
            return acumulador.concat(grupo)
        }));
        onAlternativasChange(nuevasAlternativas.reduce((acumulador, grupo) => {
            return acumulador.concat(grupo)
        }));
    };

    const handleCambiarPesoAlternativa = (
        alternativaId: string,
        nuevoPeso: number
    ) => {
        // Actualizar el texto de la alternativa con el ID proporcionado
        const nuevasAlternativas = alternativas.map((alternativa) =>
            alternativa.id === alternativaId
                ? { ...alternativa, peso: nuevoPeso }
                : alternativa
        );
        setAlternativas(nuevasAlternativas);

        // Llamar a la función de devolución de llamada para enviar las alternativas actualizadas
        onAlternativasChange(nuevasAlternativas);
    };


    if (type === "Verdadero" || type === "Falso" || type === "V" || type === "F") {
        return (
            <div className="max-w-lg w-full space-y-4 p-4 bg-white rounded-xl shadow-xl">
                <h2 className=" text-grey-darker text-center">Alternativa</h2>
                <p> La pregunta sera de tipo Verdadero y Falso, con la opción correcta {type}.</p>
            </div>
        );
    }
    else if (type === "multiple_choice" || type === "Opción Multiple") {
        return (
            <div className="max-w-lg w-full space-y-4 p-4 bg-white rounded-xl shadow-lg">
                <label className="font-semibold text-gray-600 py-2">Alternativas:</label>
                <ul>
                    {alternativas.map((alternativa) => (
                        <li className="flex" key={alternativa.id}>
                            <div>
                                <input
                                    className="
                                    appearance-none 
                                    block w-full 
                                    bg-grey-lighter 
                                    text-grey-darker 
                                    border border-grey-lighter 
                                    rounded-lg  p-2 px-4 hover:border-amber-400
                                    mt-2"
                                    type="text"
                                    value={alternativa.texto}
                                    onChange={(e) =>
                                        handleCambiarTextoAlternativa(alternativa.id, e.target.value)
                                    }
                                />{" "}
                                <div className="grid sm:grid-cols-2 sm:gap-2 sm:mt-2">
                                    <button
                                        className="bg-green-500 sm:p-2 rounded-full hover:bg-green-400 text-white"
                                        onClick={() => handleSeleccionarCorrecta(alternativa)}>
                                        Seleccionar como Correcta
                                    </button>{" "}
                                    <button
                                        className="bg-red-500 sm:p-2 rounded-full hover:bg-red-400 text-white"
                                        onClick={() => handleBorrarAlternativa(alternativa.id)}>
                                        Borrar
                                    </button>
                                </div>
                            </div>
                            {alternativa.id === alternativaCorrecta?.id ?
                                <div className="my-auto mx-2">
                                    <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0">
                                        </g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round">
                                        </g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#6dd93f">
                                            </path>
                                        </g>
                                    </svg>
                                </div>
                            :
                            <></>
                            }
                        </li>
                    ))}
                </ul>
                <button
                    className="

                        mb-2 md:mb-0 bg-amber-400 px-2 py-1 
                        text-sm shadow-sm font-medium 
                        text-white rounded-full hover:shadow-lg 
                        hover:bg-amber-500"


                    onClick={handleAgregarAlternativaMultiChoise}>Agregar Alternativa</button>
                <div>
                    Alternativa correcta seleccionada: {alternativaCorrecta?.texto || "Ninguna"}
                </div>
            </div>
        );
    }
    else if (type === "ordering") {
        return (
            <div className="max-w-lg w-full space-y-4 p-4 bg-white rounded-xl shadow-lg">
                <label className="font-semibold text-gray-600 py-2">Alternativas:</label>
                <ul>
                    {alternativas.map((alternativa, index) => (
                        <li key={alternativa.id}>

                            <div className="inline-flex">
                                <input
                                    className="
                                    inline-flex
                                    appearance-none 
                                    block w-flex
                                    bg-grey-lighter 
                                    text-grey-darker 
                                    border border-grey-lighter 
                                    rounded-lg  p-2 px-4 hover:border-amber-400
                                    mt-2"
                                    type="text"
                                    value={alternativa.texto}
                                    onChange={(e) =>
                                        handleCambiarTextoAlternativa(alternativa.id, e.target.value)
                                    }
                                />{" "}

                                <p className="
                                    ml-4 py-4 p-2 px-2 bg-grey-lighter rounded-lg  
                                    text-grey-darker 
                                    border border-grey-lighter mt-2">{alternativa.peso}</p>

                            </div>
                            <div className="grid sm:grid-cols-2 sm:gap-2 sm:mt-2">

                                <button
                                    className="bg-red-500 sm:p-2 rounded-full hover:bg-red-400 text-white"
                                    onClick={() => handleBorrarAlternativaOrder(alternativa.id)}>
                                    Borrar
                                </button>
                            </div>

                        </li>
                    ))}
                </ul>
                <button
                    className="
                     mb-2 md:mb-0 bg-amber-400 px-2 py-1 
                     text-sm shadow-sm font-medium 
                     text-white rounded-full hover:shadow-lg 
                     hover:bg-amber-500"

                    onClick={handleAgregarAlternativaOrder}>Agregar Alternativa</button>
            </div>
        );
    }
    else if (type === "grouping" || type === "Relacionar de uno a uno") {
        return (
            <div className="max-w-lg w-full space-y-4 p-4 bg-white rounded-xl shadow-lg">
                <label className="font-semibold text-gray-600 py-2">Alternativas:</label>
                <ul>
                    {alternativasGrupo.map((alternativa, index) => (
                        <li key={`${index}-alternativa`}>
                            <div className="flex">
                                <div className="mx-2">
                                    <p className="font-semibold text-gray-600 py-2">Texto</p>
                                    <input
                                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg p-2 px-4 hover:border-amber-400 mt-2"
                                        type="text"
                                        value={alternativa[0].texto}
                                        onChange={(e) =>
                                            handleCambiarTextoAlternativaGrupo(index, e.target.value, 0)
                                        }
                                    />{" "}
                                </div>
                                <div className="mx-2">
                                    <p className="font-semibold text-gray-600 py-2">Texto</p>
                                    <input
                                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg p-2 px-4 hover:border-amber-400 mt-2"
                                        type="text"
                                        value={alternativa[1].texto}
                                        onChange={(e) =>
                                            handleCambiarTextoAlternativaGrupo(index, e.target.value, 1)
                                        }
                                    />{" "}
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 sm:gap-2 sm:mt-2">
                                <button
                                    className="bg-red-500 sm:p-2 rounded-full hover:bg-red-400 text-white"
                                    onClick={() => handleBorrarAlternativaGrupo(index)}>
                                    Borrar
                                </button>
                            </div>

                        </li>
                    ))}
                </ul>
                <button
                    className="

                        mb-2 md:mb-0 bg-amber-400 px-2 py-1 
                        text-sm shadow-sm font-medium 
                        text-white rounded-full hover:shadow-lg 
                        hover:bg-amber-500"


                    onClick={handleAgregarAlternativaGrupo}>Agregar Alternativa</button>

                <div className="border-dashed border-2 border-amber-300 p-2 rounded-lg">
                    <div>
                        <div>
                            <div className="p-2 w-32">
                                <h1 className=" font-semibold text-gray-600  border-b-2 border-amber-400">Recomendacion:</h1>

                            </div>
                            <p> En este tipo de pregunte debe colocar el mismo número en peso
                                de las opciones que se vayan a relacionar comenzando desde el 1, como por ejemplo:</p>

                        </div>

                    </div>
                    <div className="mt-4">

                        <ul>
                            <li className="p-2 ">
                                <p>Texto: Alternativa con peso 1 </p>
                                <p>Peso: 1</p>
                            </li>
                            <li className="p-2">
                                <p>Texto: Alternativa a relacionar con el anterior peso 1</p>
                                <p>Peso: 1</p>
                            </li>
                            <li className="p-2">
                                <p>Texto: Alternativa con peso 1 número 3 (naranja)</p>
                                <p>Peso: 1</p>
                            </li>
                            <li className="p-2">
                                <p>Texto: Caja para las alternativas con peso 1 (frutas)</p>
                                <p>Peso: 100</p>
                            </li>
                        </ul>

                    </div>
                </div>


            </div>
        );
    } else if (type === "boxes" || type === "Agrupar en cajas") {
        return (
            <div className="max-w-lg w-full space-y-4 p-4 bg-white rounded-xl shadow-lg">
                <label className="font-semibold text-gray-600 py-2">Alternativas:</label>
                <ul>
                    {alternativasGrupo.map((alternativas, index) => (
                        <li key={`${index}-alternativa`}>
                            <p className="font-semibold text-gray-600 py-2">Texto</p>
                            <input
                                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg p-2 px-4 hover:border-amber-400 mt-2"
                                type="text"
                                value={alternativas[0].texto}
                                onChange={(e) =>
                                    handleCambiarTextoAlternativaGrupo(index, e.target.value, 0)
                                }
                            />{" "}
                            <div className="grid grid-cols-2 gap-4">
                                {alternativas.slice(1).map((alternativa, subIndex) => (
                                    <div className="mx-2">
                                        <p className="font-semibold text-gray-600 py-2">Hijo</p>
                                        <input
                                            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg p-2 px-4 hover:border-amber-400 mt-2"
                                            type="text"
                                            value={alternativa.texto}
                                            onChange={(e) =>
                                                handleCambiarTextoAlternativaGrupo(index, e.target.value, (subIndex + 1))
                                            }
                                        />{" "}
                                    </div>
                                ))}
                                <div className="mx-2">
                                    <p className="font-semibold text-gray-600 py-2">Agregar Hijo</p>
                                    <button
                                        className="mb-2 md:mb-0 bg-emerald-400 px-2 py-1 text-sm shadow-sm font-medium text-white rounded-lg hover:shadow-lghover:bg-emerald-500 mt-2"
                                        onClick={() => handleAgregarSubAlternativaGrupo(index)}>Agregar Alternativa</button>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 sm:gap-2 sm:mt-2">
                                <button
                                    className="bg-red-500 sm:p-2 rounded-full hover:bg-red-400 text-white"
                                    onClick={() => handleBorrarAlternativaGrupo(index)}>
                                    Borrar
                                </button>
                            </div>

                        </li>
                    ))}
                </ul>
                <button
                    className="

                        mb-2 md:mb-0 bg-amber-400 px-2 py-1 
                        text-sm shadow-sm font-medium 
                        text-white rounded-full hover:shadow-lg 
                        hover:bg-amber-500"


                    onClick={handleAgregarAlternativaGrupo}>Agregar Alternativa</button>

                <div className="border-dashed border-2 border-amber-300 p-2 rounded-lg  ">
                    <div>
                        <div className="p-2 w-32">
                            <h1 className=" font-semibold text-gray-600  border-b-2 border-amber-400">Recomendacion:</h1>

                        </div>
                        <p> En este tipo de pregunte debe colocar en peso un número 1, 2 o 3 para las opciones que iran dentro de las cajas,
                            y para las opciones de las cajas ese mismo número multiplicado por 100, como por ejemplo:</p>

                    </div>
                    <div className="mt-4">
                        <ul>
                            <li className="p-2 ">
                                <p>Texto: Alternativa con peso 1 número 1 (manzana)</p>
                                <p>Peso: 1</p>
                            </li>
                            <li className="p-2">
                                <p>Texto: Alternativa con peso 1 número 2 (pera)</p>
                                <p>Peso: 1</p>
                            </li>
                            <li className="p-2">
                                <p>Texto: Alternativa con peso 1 número 3 (naranja)</p>
                                <p>Peso: 1</p>
                            </li>
                            <li className="p-2">
                                <p>Texto: Caja para las alternativas con peso 1 (frutas)</p>
                                <p>Peso: 100</p>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        );
    }
    return null;

};

export default Alternativas;