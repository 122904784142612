import { useState } from "react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button, NavbarMenuToggle, NavbarMenu, NavbarMenuItem } from "@nextui-org/react";


import ipc from "../assets/Header/ipc.png"
import LogoutButton from "../components/LogoutButton/LogoutButton";

const Header: React.FC = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuItems = [
        "Profile",
        "Dashboard",
        "Activity",


    ];

    return (
        <Navbar className="bg-lime-400" position="static" onMenuOpenChange={setIsMenuOpen} >

            <NavbarContent >
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="sm:hidden"
                />
                <NavbarBrand >
                    <img className="bg-transparent h-[66px] " src={ipc} alt="ipc" />
                    <p className="text-xl text-white font-bold italic text-orange-400 hidden sm:block">
                        International Project Community
                    </p>
                    <p className="text-xl text-white font-bold italic text-orange-400 sm:hidden">
                        IPC
                    </p>
                </NavbarBrand>
            </NavbarContent>


            <NavbarContent justify="end">

                <NavbarItem className="
                    transition ease-in-out 
                    delay-150 
                    hover:scale-105 hidden
                    sm:block hover:bg-amber-400 
                    hover:rounded-lg
                    rounded-lg
                  " >

                    <LogoutButton />

                </NavbarItem>
            </NavbarContent>
            <NavbarMenu>
                <NavbarMenuItem >
                    <LogoutButton />

                </NavbarMenuItem>

            </NavbarMenu>
        </Navbar>

    );
};

export default Header;
