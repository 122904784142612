import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CreateSession from '../Pages/Login/CreateSession';
import CursoLogout from './CursoLogout/CursoLogout';
import Footer from '../static/Footer';

import EtiquetasService from '../services/EtiquetasService';
import ExamenesService from '../services/ExamenesService';
import Swal from 'sweetalert2';

interface LinkItem {
    to: string;
    label: string;
    type: string;
}

interface ModeData {
    title: string;
    icon: string;
    type: string;
    data_link: string;
    link: boolean;
    links: LinkItem[];
}

interface ModeProps {
    modes: ModeData[];
}

interface Elementodata {
    id: string;
    nombre: string;
    usuario_curso: string;
}

interface Elementosdata {
    [key: string]: Elementodata[];
}

interface Etiquetas {
    id: string;
    nombre: string;
}

interface DataEtiquetas {
    id: number;
    nombre: string;
    etiquetas: Etiquetas[];
}


const Mode: React.FC<ModeProps> = ({ modes }) => {

    const selectedCourse = sessionStorage.getItem('selectedCourse') ?? '';
    const selectedCourseId = sessionStorage.getItem('selectedCourseId') ?? '';

    const navigate = useNavigate();

    const [data, setData] = useState<Elementosdata>({});
    const [formOpen, setFormOpen] = useState(false);
    const [formData, setFormData] = useState<DataEtiquetas[]>([]);
    const [tagSelected, setTagSelected] = useState<Etiquetas[]>([]);
    const [type, setType] = useState('');
    const initialShowMoreStates = modes.map(() => false); // Estado inicial para cada contenedor
    const [showMoreStates, setShowMoreStates] = useState(initialShowMoreStates); // Estado para controlar "Ver más" en cada contenedor


    useEffect(() => {
        modes.forEach(item => {
            if (item.link) {
                ExamenesService.getExamenByTipo(item.data_link, selectedCourse).then((data) => {
                    setData(prevData => ({
                        ...prevData,
                        [item.data_link]: data
                    }));
                });
            }
        });
        EtiquetasService.getEtiquetasByCurso(selectedCourseId).then((data: DataEtiquetas[]) => setFormData(data));
        console.log(formData)
    }, []);

    const handleClick = async (index: number, type: string) => {
        if (index === -1) {
            setTagSelected([{ 'id': 'random', 'nombre': 'random' }])
        } else {
            setTagSelected(formData[index].etiquetas);
        }
        setType(type)
        setFormOpen(true);
    };


    const onClose = () => {
        setFormOpen(false);
    };

    const toggleShowMore = (index: number) => {
        const newShowMoreStates = [...showMoreStates];
        newShowMoreStates[index] = !newShowMoreStates[index];
        setShowMoreStates(newShowMoreStates);
    };

    const handleApiError = () => {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Se ha producido un error al cargar los datos. Por favor, verifique la fecha de vencimiento o póngase en contacto con el administrador del sistema',
        });

        console.error('Error en la API:');

        navigate('/setofcourse');
    };
    return (
        <>
            <div className="2xl:container mx-auto lg:px-20 md:py-12 md:px-6 py-9 px-4  bg-gradient-to-r from-zinc-100 via-zinc-100 to-zinc-100">
                <div className='flex mb-10  '>
                    <CursoLogout />
                </div>

                {formOpen ?
                    <div className="fixed inset-0 flex items-center justify-center y-20 z-50 bg-opacity-50 bg-gray-900">
                        {
                            <CreateSession onClose={onClose} data={tagSelected} label={"Etiquetas"} type={type} />
                        }
                    </div>
                    :
                    <></>
                }
                <div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 gap-6  ">
                    {modes.map((mode, index) => (

                        < div className="group transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110
                    p-6 bg-white border-2 border-transparent  hover:border-amber-300 rounded-lg " >
                            <img src={mode.icon} alt="Icono SVG" className="w-full h-44" />
                            <p className="cursor-pointer border-b-2 p-2 group-hover:border-amber-300 group-hover:text-amber-300 border-gray-300 text-xl text-gray-800 text-center  font-semibold leading-5 mt-6">{mode.title}</p>

                            <ul className='mt-4 ' >
                                {mode.type !== 'resultados' ?
                                    <li key={-1} className="mb-2 cursor-pointer text-base leading-4 font-medium  text-gray-800 hover:text-amber-400 ">
                                        <a onClick={() => handleClick(-1, mode.type)
                                        } >
                                            Aleatorio
                                        </a>
                                    </li>
                                    :
                                    ''
                                }
                                {mode.type === 'pruebas' ?
                                    formData.map((course, index) => (
                                        <li key={index} className="mb-2 cursor-pointer text-base leading-4 font-medium  text-gray-800 hover:text-amber-400 ">
                                            <a onClick={() => handleClick(index, mode.type)
                                            } >
                                                Por {course.nombre}
                                            </a>
                                        </li>
                                    ))
                                    :
                                    ''
                                }
                            </ul>

                            <div key={index}>
                                <ul className='mt-4'>
                                    {mode.link ?
                                        data[mode.data_link]?.slice(0, showMoreStates[index] ? data[mode.data_link]?.length : 5).map((item, linkIndex) => {
                                            try {
                                                return (
                                                    <li key={linkIndex} className="font-normal text-base leading-6 text-blue-700 hover:text-blue-600 hover:font-semibold">
                                                        <Link to={`/${mode.data_link}${item.id}`}>
                                                            {item.nombre}
                                                        </Link>
                                                    </li>
                                                );
                                            } catch (error) {
                                                // Manejar el error y mostrar una alerta
                                                handleApiError();
                                                return null; 
                                            }
                                        })
                                        :
                                        Object.values(data).flat().slice(0, showMoreStates[index] ? Object.values(data).flat().length : 5).map((item, linkIndex) => {
                                            try {
                                                return (
                                                    <li key={linkIndex} className="font-normal text-base leading-6 text-blue-700 hover:text-blue-600 hover:font-semibold">
                                                        <Link to={`/${mode.data_link}${item.id}`}>
                                                            {item.nombre}
                                                        </Link>
                                                    </li>
                                                );
                                            } catch (error) {
                                                // Manejar el error y mostrar una alerta
                                                handleApiError();
                                                return null; 
                                            }
                                        })
                                    }
                                </ul>
                                <button className="text-amber-500 hover:text-amber-400" onClick={() => toggleShowMore(index)}>
                                    {showMoreStates[index] ? 'Ocultar' : 'Ver más'}
                                </button>
                            </div>
                        </div>
                    ))
                    }
                </div >
            </div >
            <div>
                <Footer />
            </div>
        </>
    );
};

export default Mode;
