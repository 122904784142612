import React, { useState, useEffect } from "react";

import { Dialog } from 'primereact/dialog';
import UsersService from "../../../services/UsersService";
import { UserInterface } from "../../../models/UsersModel";

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

interface propsCursoDetail {
    user: UserInterface;
    visible: boolean;
    setVisible: (activo: boolean) => void;
}

const UserDetail: React.FC<propsCursoDetail> = ({ user, visible, setVisible }) => {

    const [cursos, setCursos] = useState();
    const [selectedCurso, setSelectedCurso] = useState<any>(null);
    const [filters, setFilters] = useState<any>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'curso.nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        fecha_caducidad: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    useEffect(() => {
        if (user?.id){
            UsersService.getCursosByUser(user.id.toString()).then((data) => setCursos(data));
        }
    }, [user]);

    const onGlobalFilterChange = (event: any) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className="flex flex-wrap gap-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <div className="card flex justify-content-center">
            <Dialog header="Detalle" visible={visible} style={{ width: '50vw', borderBottom: '2px solid orange' }} onHide={() => setVisible(false)}>


                <div className="drop-shadow-lg bg-sky-50 mx-auto p-4 rounded-xl flex my-2 border-dashed border-2 border-amber-500">
                    <div>
                        <svg width="180px" height="180px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M19.5119 5.85L13.5719 2.42C12.6019 1.86 11.4019 1.86 10.4219 2.42L4.49187 5.85C3.52187 6.41 2.92188 7.45 2.92188 8.58V15.42C2.92188 16.54 3.52187 17.58 4.49187 18.15L10.4319 21.58C11.4019 22.14 12.6019 22.14 13.5819 21.58L19.5219 18.15C20.4919 17.59 21.0919 16.55 21.0919 15.42V8.58C21.0819 7.45 20.4819 6.42 19.5119 5.85ZM12.0019 7.34C13.2919 7.34 14.3319 8.38 14.3319 9.67C14.3319 10.96 13.2919 12 12.0019 12C10.7119 12 9.67188 10.96 9.67188 9.67C9.67188 8.39 10.7119 7.34 12.0019 7.34ZM14.6819 16.66H9.32187C8.51187 16.66 8.04187 15.76 8.49187 15.09C9.17187 14.08 10.4919 13.4 12.0019 13.4C13.5119 13.4 14.8319 14.08 15.5119 15.09C15.9619 15.75 15.4819 16.66 14.6819 16.66Z" fill="#8f9194"></path> </g></svg>
                    </div>
                    <div className="p-8">
                        <div>
                            <p className="font-semibold text-gray-600 py-2">Nombre de usuario: {user?.username}</p>
                        </div>
                        <div>
                            <p  className="font-semibold text-gray-600 py-2">Nombre: {user?.first_name} {user?.last_name}</p>
                        </div>
                        <div>
                            <p className="font-semibold text-gray-600 py-2"> Correo: {user?.email}</p>

                        </div>
                    </div>
                </div>

                <DataTable className="border-2 drop-shadow-lg  rounded-xl" value={cursos} paginator rows={6} header={header} filters={filters} onFilter={(e) => setFilters(e.filters)}
                    selection={selectedCurso} onSelectionChange={(e) => setSelectedCurso(e.value)} selectionMode="single" dataKey="id"
                    emptyMessage="No se encontraron cursos." tableStyle={{ minWidth: '25rem' }}>
                    <Column field="curso.nombre" header="Curso" sortable style={{ width: '40%' }}></Column>
                    <Column field="fecha_caducidad" header="Fecha de caducidad" sortable filterMenuStyle={{ width: '14rem' }} style={{ width: '20%' }}></Column>
                </DataTable>
            </Dialog>
        </div>
    )

};
export default UserDetail;