import api from './api';

class UsersService {

  public getUsers() {
    return api.get(`user/`)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  public getSimpleUsers() {
    return api.get(`user-simple/`)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  public getCursosByUser(id: string) {
    return api.get(`usuario-curso/${id}/`)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  public PostUser(data: any) {
    return api.post(`user/`, data)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  public PutUser(data: any) {
    return api.post(`user/`, data)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };
}

export default new UsersService();
