import ipc from '../../assets/Header/ipc.png';
import { API_SIMPLE } from "../../services/api";

interface PropsUnitCourse {
    id: string;
    aceptado: boolean;
    course_id: string;
    name: string;
    row: number;
    dateExpiry: string;
    image: string;
    onCourseClick: (course: string, course_id: string) => void;
}


export default function UnitCourse(props: PropsUnitCourse) {
    const targetDate = new Date(props.dateExpiry);
    const today = new Date();
    const timeDifference = targetDate.getTime() - today.getTime();
    // Convierte la diferencia en milisegundos a días
    const daysRemaining = Math.ceil(timeDifference / (1000 * 3600 * 24));
    const messageSub = (daysRemaining: number) => {
        if (daysRemaining === 0) {
            return <p className="group-hover:text-white text-sm  mt-2">CUIDADO, hoy vence su subscripción.</p>;
        }
        if (daysRemaining < 0) {
            return <p className="group-hover:text-white text-sm mt-2">Su subscripción vencio. Por favor renueve su subscripción.</p>;
        }
        if (daysRemaining > 0) {
            return <p className="group-hover:text-white text-sm mt-2">Puede disfrutar su subscripción por {daysRemaining} días.</p>;
        }
    };
    const messageComponent = messageSub(daysRemaining);

    function Color(row: number) {
        if (row === 1) {
            return ("bg-[#f5a631]")
        }
        if (row === 2) {
            return ("bg-[#5ca57a]")
        }
        if (row === 3) {
            return ("bg-[#bfd33f]")
        }
    }

    function linkToCourse() {
        if(props.aceptado) {
            props.onCourseClick(props.id, props.course_id); // Llamar a la función pasada como prop
        }
    }

    let link_image = ''
    
    if (props.image === undefined || props.image === null ) {
        link_image = ipc
    } else {
        link_image = props.image
    }
    
    return (
        <button onClick={linkToCourse}>
            <div className={`group transition ease-in-out delay-150 hover:-translate-y-1 hover:md:scale-110 sm:transform-none max-w-md mx-auto  rounded-xl shadow-md overflow-hidden md:max-w-2xl  ${Color(props.row)} `}>
                <div className="md:flex">
                    <div className="md:shrink-0">
                        <img className="h-36 w-full md:max-w-full object-cover md:h-36" src={link_image} alt="Modern building architecture" />
                    </div>
                    <div className="p-8 pl-2">
                        <div className="uppercase group-hover:text-white  text-slate-100 border-b-2  group-hover:border-white border-gray-200 font-semibold leading-5 text-foreground/90">{props.name}</div>
                        <a href="#" className="block mt-1 text-lg leading-tight font-medium group-hover:text-white font-semibold leading-5 text-small text-foreground/80 ">Fecha de caducidad:</a>
                        <p className="group-hover:text-white font-semibold leading-5 text-small text-foreground/80">{props.dateExpiry}</p>
                        {messageComponent}
                    </div>
                </div>

            </div>
        </button>
    );

}