
import React, { useRef } from 'react';
import { useState } from 'react';
import MultipleChoice from '../../components/QuestionFormat/MultipleChoice';
import Pagination from '../../components/QuestionFormat/Pagination';
import OrderQuestion from '../../components/QuestionFormat/OrderQuestion';
import RelationalChoice from '../../components/QuestionFormat/RelationalChoice';
import TrueFalseQuestion from '../../components/QuestionFormat/TrueFalseQuestion';
import BoxesQuestion from '../../components/QuestionFormat/BoxesQuestion';
import CountdownTimer from '../../components/CompTestLayout/CountdownTimer';

import ExamenesService from '../../services/ExamenesService';
import Swal from 'sweetalert2';
import { showSuccess } from '../../services/ToastService';
import { Toast } from 'primereact/toast';
interface ExamLayoutProps {
  name: string;
  test: QuestionnaireProps;
  onFinish: () => void;
  onCancel: () => void;
}

interface Alternativa {
  id: string;
  texto: string;
  peso: number;
}


interface Pregunta {
  id: string;
  texto: string;
  tipo: string;
  etiquetas: string[];
  alternativas: Alternativa[] | Alternativa[][];
  complete?: boolean;
}

interface QuestionnaireProps {
  id: string;
  nombre: string;
  preguntas: Pregunta[];
  tipo: string;
  fecha: Date;
  finalizado: boolean;
  tiempo: Date;
  respuestas?: Answer[];
}

interface Answer {
  id: string;
  solucion: Solution[];
  pregunta: string;
}

interface Solution {
  id: string;
  peso: number;
  alternativa?: string;
}

const TestLayout: React.FC<ExamLayoutProps> = ({ test, name, onFinish, onCancel }) => {

  const [examen, setExamen] = useState<QuestionnaireProps>(test)
  console.log(examen)
  const [quetionSelected, setQuetionSelected] = useState<Pregunta>(examen.preguntas[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  //Aqui se cambian los tipos de preguntas como lleguen de la api
  const respuestaPregunta = examen.respuestas?.find(respuesta => respuesta.pregunta === quetionSelected.id);


  const toast = useRef<Toast>(null);

  const handleSaveButtonClick = () => {
    setIsNextButtonDisabled(false); // Desbloquear el botón "Siguiente" al guardar desde el componente hijo

    if (currentIndex >= 0 && currentIndex < examen.preguntas.length) {
      if (examen.preguntas[currentIndex]) {
        examen.preguntas[currentIndex].complete = true;
        setExamen(examen);

      }
    }
  }

  const postRespuesta = (respuesta: any, examencod: string) => {
    ExamenesService.patchExamen(examencod, respuesta, String(sessionStorage.getItem("selectedCourse"))).then((data: { respuestas: Answer[] }) => {
      console.log("Pregunta subida realizada correctamente")
      console.log(data)
      const updatedExamen = { ...examen, respuestas: examen.respuestas?.concat(data.respuestas) };
      setExamen(updatedExamen);
    });
  }

  //Lógica para el contador:
  console.log(examen.preguntas.length)

  const fechaActual = new Date();
  const examenFechaInit = new Date(examen.fecha);
  const examenFechaFin = new Date(examen.tiempo);
  const examenFecha = Math.floor((examenFechaFin.getTime() - fechaActual.getTime()) / 1000)
  let counter = 0
  let dateInit = examenFechaInit;
  if (examen.tipo === "E") {
    counter = examenFecha > 0 ? examenFecha : 0;
    dateInit = examenFechaFin;
  } else {
    counter = Math.floor((fechaActual.getTime() - examenFechaInit.getTime()) / 1000);
    dateInit = examenFechaInit;
  }
  console.log(counter)

  const next = () => {
    const nextIndex = currentIndex + 1;
    console.log("funca")

    if (nextIndex < examen.preguntas.length) {
      setCurrentIndex(nextIndex);
      setQuetionSelected(examen.preguntas[nextIndex]);
    } else {
      // Si es la última pregunta, muestra un mensaje con SweetAlert
      Swal.fire({
        title: '¡Atención!',
        text: 'Ha llegado a la última pregunta. Por favor, finalice el examen.',
        icon: 'info',
        confirmButtonText: 'OK',
      });
    }
  };

  const questionType = () => {
    try {
      if (quetionSelected.tipo === "V/F") {
        return <TrueFalseQuestion
          editable={true}
          respuestas={respuestaPregunta}
          examen={examen.id}
          next={next}
          texto={quetionSelected.texto}
          id={quetionSelected.id}
          options={[{ "V": "Verdadero" }, { "F": "Falso" }]}
          handlePost={postRespuesta}
          onButtonClick={() => { handleSaveButtonClick(); showSuccess(toast, 'Pregunta guardada'); }} />
      }
      if (quetionSelected.tipo === "Agrupamiento") {
        return <RelationalChoice
          editable={true}
          examen={examen.id}
          id={quetionSelected.id}
          respuestas={respuestaPregunta}
          next={next}
          texto={quetionSelected.texto}
          options={quetionSelected.alternativas as Alternativa[][]}
          handlePost={postRespuesta}
          onButtonClick={() => { handleSaveButtonClick(); showSuccess(toast, 'Pregunta guardada'); }} />
      }
      if (quetionSelected.tipo === "Cajas") {
        return <BoxesQuestion
          editable={true}
          examen={examen.id}
          respuestas={respuestaPregunta}
          id={quetionSelected.id}
          next={next}
          texto={quetionSelected.texto}
          options={quetionSelected.alternativas as Alternativa[][]}
          handlePost={postRespuesta}
          onButtonClick={() => { handleSaveButtonClick(); showSuccess(toast, 'Pregunta guardada'); }} />
      }
      if (quetionSelected.tipo === "Opcion multiple") {
        const optionsMultipleChoise = quetionSelected.alternativas.map((alternativa) => ({
          [(alternativa as Alternativa).id]: (alternativa as Alternativa).texto,
        }))
        return <MultipleChoice
          editable={true}
          respuestas={respuestaPregunta}
          examen={examen.id}
          next={next}
          texto={quetionSelected.texto}
          id={quetionSelected.id}
          options={optionsMultipleChoise}
          handlePost={postRespuesta}
          onButtonClick={() => { handleSaveButtonClick(); showSuccess(toast, 'Pregunta guardada'); }}/>
      }
      if (quetionSelected.tipo === "Ordenamiento") {
        const optionsOrder = quetionSelected.alternativas.map((alternativa) => ({
          [(alternativa as Alternativa).id]: (alternativa as Alternativa).texto,
        }))
        return <OrderQuestion
          editable={true}
          respuestas={respuestaPregunta}
          examen={examen.id}
          next={next}
          texto={quetionSelected.texto}
          id={quetionSelected.id}
          options={optionsOrder}
          handlePost={postRespuesta}
          onButtonClick={() => { handleSaveButtonClick(); showSuccess(toast, 'Pregunta guardada'); }} />
      }
    } catch (error) {
      console.log(error)
    }
    return <>Este examen no tiene preguntas.</>
  }

  const chargeQuestion = questionType()

  const handleSelect = (index: number) => {
    setQuetionSelected(examen.preguntas[index])
    setCurrentIndex(index);
  }


  return (
    <div className="flex flex-col items-center min-h-screen bg-neutral-100 shadow-md rounded-md" >
      <div className="w-5/6 bg-white text-black text-center text-xl italic p-4 rounded-md my-2 mx-4">{name}</div>

      <div className="w-full md:flex md:justify-between drop-shadow-xl">
        {/* Panel Izquierdo - Paginacion */}

        <div className="
            md:w-1/4 w-full 
            p-6 bg-white 
            sm:h-[calc(94vh-3rem)]  
            shadow-md rounded-md 
            mx-4 md:mb-0 mb-4
        
             ">

          <div className="bg-amber-400 text-white text-xl p-4 rounded-md mb-4">Panel Izquierdo</div>

          {/* Contador */}

          <CountdownTimer initialDuration={counter} type={examen.tipo} dateInit={dateInit} />

          <div className="py-2">
            <Pagination handleSelect={handleSelect} preguntas={examen.preguntas} respuestas={examen.respuestas} selected={currentIndex} />
          </div>

          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={onCancel}
          >
            Cancelar
          </button>
        </div>


        {/* Panel Derecho - Pregunta y Alternativas */}

        <div className="md:w-3/4 w-full sm:h-[calc(94vh-3rem)] p-6 bg-white shadow-md rounded-md overflow-y-auto no-scrollbar">
          <Toast ref={toast} />
          {chargeQuestion}

          {/* Botón de Terminar */}
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-md mt-4"
            onClick={onFinish}
          >
            Finalizar
          </button>
        </div>
      </div>
    </div>
  );

};

export default TestLayout;
