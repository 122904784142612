import { AxiosResponse } from "axios";
import api from "./api";

class LoginService {

    public Login(username: string, password: string): Promise<AxiosResponse<any>> { 
        return api.post(`token/`, { username, password })
            .then((response: AxiosResponse<any>) => response)
            .catch((error: any) => {
                console.error("Error al subir la pregunta:", error);
                return error; 
            });
    };

    
}

export default new LoginService();
