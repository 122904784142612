

export const TiposPreguntas: {
    [key: string]: string[]} = {
    'V': ['Verdadero/Falso', 'success'],
    'F': ['Verdadero/Falso', 'success'],
    'multiple_choice': ['Opcion multiple', 'warning'],
    'multiple_response': ['Alternativa multiple', 'danger'],
    'ordering': ['Ordenamiento', 'info'],
    'boxes': ['Cajas', ''],
    'grouping': ['Agrupamiento', 'info'],
    'Verdadero': ['Verdadero/Falso', 'success'],
    'Falso': ['Verdadero/Falso', 'success'],
    'Opcion multiple': ['Opcion multiple', 'warning'],
    'Alternativa multiple': ['Alternativa multiple', 'danger'],
    'Ordenamiento': ['Ordenamiento', 'info'],
    'Cajas': ['Cajas', ''],
    'Agrupamiento': ['Agrupamiento', 'info']
}
export const TiposPreguntasList: string[] = ['V', 'F', 'multiple_choice', 'multiple_response', 'ordering', 'boxes', 'grouping'];

export interface PreguntaInterface {
    id: string;
    texto: string;
    tipo: string;
    etiquetas: string[];
}

export interface ListItem {
    id: string;
    texto: string;
    peso?: number;
}

export interface Answer {
    id: string;
    solucion: Solution[];
    pregunta: string;
}

export interface Solution {
    id: string;
    peso: number;
    alternativa?: string;
}

