import React, { useState, useEffect } from "react";

import { Dialog } from 'primereact/dialog';
import UsersService from "../../../services/UsersService";
import CursosService from "../../../services/CursosService";
import { CursoInterfacePost } from "../../../models/CursosModel";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

interface propsCursoDetail {
    curso: CursoInterfacePost;
    visible: boolean;
    setVisible: (activo: boolean) => void;
}

const CursoDetail: React.FC<propsCursoDetail> = ({ curso, visible, setVisible }) => {

    const [filters, setFilters] = useState<any>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'descripcion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },

    });

    

    const onGlobalFilterChange = (event: any) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className="flex flex-wrap gap-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <div className="card flex justify-content-center">


            <Dialog header="Detalles del curso" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <div>
                                <p className="font-semibold text-gray-600 py-2">Nombre del curso: {curso?.nombre}</p>


                            </div>
                            <div>
                                <p className="font-semibold text-gray-600 py-2">Descripcion: {curso?.descripcion}</p>

                            </div>
                            {curso?.imagen_url && (
                                <div>
                                    <img src={curso?.imagen_url} alt="Imagen del curso" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                                </div>
                            )}


                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )

};
export default CursoDetail;