import React, { useState, useEffect } from "react";

import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { error } from "console";
import EtiquetasService from "../../../services/EtiquetasService";

interface propsEtiquetaCreate {
    visible: boolean;
    setVisible: (activo: boolean) => void;
    response: (data: any) => void;
}

const EtiquetaCreate: React.FC<propsEtiquetaCreate> = ({ visible, setVisible, response }) => {

    const [etiqueta, setEtiqueta] = useState('');
    const [submit, setSubmit] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmit(true);
        if (etiqueta !== '') {
            const dataPost = {
                nombre: etiqueta,
            }
            EtiquetasService.postEtiquetas(dataPost).then(data => {
                //response(data);
                setVisible(false);
                setEtiqueta('');
                setSubmit(false);
            });
        }
    }

    return (
        <div className="card flex justify-content-center">
            <Dialog header="Nueva Etiqueta Base" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6" action="#">
                                <div>
                                    <label htmlFor="etiqueta" className="block mb-2 text-sm font-medium text-gray-900">Nombre de la Etiqueta</label>
                                    <input value={etiqueta} onChange={(e) => setEtiqueta(e.target.value)} type="text" name="etiqueta" id="etiqueta" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="etiqueta" />
                                    <div className="text-xs text-red-600">
                                        {submit ? (etiqueta !== '' ? '' : '* El nombre es requerido.') : ''}
                                    </div>
                                </div>
                                <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Crear Etiqueta</button>
                            </form>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )

};
export default EtiquetaCreate;