import axios from 'axios';
import Cookies from 'js-cookie';
export const API = 'https://www.admin.ipc-community.org/api/v1/';
//export const API = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;
export const API_SIMPLE = 'https://www.admin.ipc-community.org/';


const api = axios.create({
  baseURL: API,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${Cookies.get('access')}`
  }
});

const redirectToLogin = () => {
  setTimeout(() => {
    window.location.href = '/login';
  }, 3000);
};

// Interceptor
api.interceptors.response.use(undefined, error => {
  if (error.config && error.response && error.response.status === 401) {
    // Si la respuesta fue 401 (no autorizado), intenta obtener un nuevo token
    return axios.post(`${API}token/refresh/`, {
      refresh: Cookies.get('refresh'),
    }).then(res => {
      // Guarda el nuevo token
      Cookies.set('access', res.data.access);
      // configura el token de autorización
      error.config.headers['Authorization'] = `Bearer ${Cookies.get('access')}`;

      // Reintenta la solicitud original
      return api.request(error.config);
    }).catch(error => {
      
      // Redirige al usuario al inicio de sesión después de renovar el token
      Cookies.remove('access');
      Cookies.remove('refresh');

      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('refreshToken');
      redirectToLogin();
    });
  }

  // Si el error no fue 401, simplemente rechaza la promesa con el error original
  return Promise.reject(error);
});

export default api;
