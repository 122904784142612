import React, { useState, useEffect, useRef } from "react";

import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";

import UsersService from "../../../services/UsersService";
import { error } from "console";
import { showSuccess } from "../../../services/ToastService";
import { showError } from "../../../services/ToastService";
import { Toast } from "primereact/toast";

interface propsUserCreate {
    visible: boolean;
    setVisible: (activo: boolean) => void;
    response: (data: any) => void;
}

const UserCreate: React.FC<propsUserCreate> = ({ visible, setVisible, response }) => {

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [username, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [admin, setAdmin] = useState(false)
    const [submit, setSubmit] = useState(false);
    const toast = useRef<Toast>(null);
    console.log("admin:", admin)
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmit(true);
        if (email !== '' && lastName !== '' && firstName !== '' && username !== '' && password !== '' && password2 !== '' && password === password2) {
            const data = {
                email: email,
                last_name: lastName,
                first_name: firstName,
                username: username,
                admin: admin,
                password: password,
            }
            UsersService.PostUser(data).then(data => {
                response(data.user);
                setVisible(false);
                setPassword('');
                setPassword2('');
                setUserName('');
                setFirstName('');
                setLastName('');
                setEmail('');
                setAdmin(false);
                setSubmit(false);
                showSuccess(toast, 'Usuario Creado');

            }).catch(error => {
                console.log(error);
                showError(toast, 'Error al crear el usuario');

            });
        }

    }

    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast} />
            <Dialog header="Nuevo usuario" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6" action="#">
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Correo electronico</label>
                                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" className={`bg-gray-50 border text-gray-900 ${submit ? (email !== '' ? 'border-gray-300' : 'border-red-300') : ' border-gray-300'} sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`} placeholder="usuario@dominio.com" />
                                    <div className="text-xs text-red-600">
                                        {submit ? (email !== '' ? '' : '* El correo electronico es requerido.') : ''}
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">Nombre de usuario</label>
                                    <input value={username} onChange={(e) => setUserName(e.target.value)} type="text" name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="usuario" />
                                    <div className="text-xs text-red-600">
                                        150 caracteres o menos. Solo letras, digitos y @/./+/-/_.
                                    </div>
                                    <div className="text-xs text-red-600">
                                        {submit ? (username !== '' ? '' : '* Los nombres son requeridos.') : ''}
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900">Nombres</label>
                                    <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" name="firstName" id="firstName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="usuario" />
                                    <div className="text-xs text-red-600">
                                        {submit ? (firstName !== '' ? '' : '* Los apellidos son requeridos.') : ''}
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900">Apellidos</label>
                                    <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" name="lastName" id="lastName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="usuario" />
                                    <div className="text-xs text-red-600">
                                        {submit ? (lastName !== '' ? '' : '* El nombre de usuario es requerido.') : ''}
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Contraseña</label>
                                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder="••••••••" className={`bg-gray-50 border ${submit ? (password !== '' ? 'border-gray-300' : 'border-red-300') : ' border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`} />
                                    <div className="text-xs text-red-600">
                                        Tu contraseña no puede ser muy similar a tu otra información personal.<br/>
                                        Tu contraseña debe contener al menos 8 caracteres.<br/>
                                        Tu contraseña no puede ser una contraseña de uso común.<br/>
                                        Tu contraseña no puede ser completamente numérica.<br/>
                                    </div>
                                    <div className="text-xs text-red-600">
                                        {submit ? (password !== '' ? '' : '* La contraseña es requerida.') : ''}
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900">Confirmar contraseña</label>
                                    <input value={password2} onChange={(e) => setPassword2(e.target.value)} type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className={`bg-gray-50 border ${submit ? (password2 !== '' ? 'border-gray-300' : 'border-red-300') : ' border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`} />
                                    <div className="text-xs text-red-600">
                                        {submit ? (password2 !== '' ? '' : '* La confirmacion de contraseña es requerida.') : ''}
                                    </div>
                                    <div className="text-xs text-red-600">
                                        {password !== password2 ? '* La contraseña es diferente' : ''}
                                    </div>
                                </div>
                                <div className="flex">
                                    <label htmlFor="admin" className="block mb-2 text-sm font-medium text-gray-900">Administrador</label>
                                    <input type="checkbox" id="admin" name="admin" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" onChange={(e) => setAdmin(e.target.checked)} />
                                </div>
                                <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Crear cuenta</button>
                            </form>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )

};
export default UserCreate;