import React, { useState, useEffect, useRef } from 'react';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';

import EtiquetasService from '../../../services/EtiquetasService';
import { EtiquetaCursoInterface, EtiquetaInterface } from '../../../models/EtiquetasModel';
import { InputText } from 'primereact/inputtext';
import EtiquetaCreate from '../Create/EtiquetaCreate';
import EtiquetaPorCurso from '../Create/EtiquetaPorCurso';

const EtiquetasTable: React.FC = () => {

    const [etiquetas, setEtiquetas] = useState<EtiquetaCursoInterface[]>([]);

    const [selectedEtiqueta, setSelectedEtiqueta] = useState<EtiquetaCursoInterface | null>(null);
    const [EtiquetaVisible, setEtiquetaVisible] = useState<boolean>(false);
    const [createDialog, setCreateDialog] = useState<boolean>(false);
    const [createDialog2, setCreateDialog2] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
    const toast = useRef<Toast>(null);

    const [filters, setFilters] = useState<any>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        texto: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    useEffect(() => {
        EtiquetasService.getEtiquetas().then((data) => setEtiquetas(data));
    }, []);

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Nueva Etiqueta Base" icon="pi pi-plus" severity="success" onClick={openNew} />
                <Button label="Nueva Etiqueta Por Curso" icon="pi pi-plus" severity="success" onClick={openNew2} />

            </div>
        );
    };

    const openNew = () => {
        setCreateDialog(true);
    };

    const openNew2 = () => {
        setCreateDialog2(true);
    };

    const onRowExpand = (event: DataTableRowEvent) => {
        toast.current?.show({ severity: 'info', summary: 'EtiquetaCursoInterface Expanded', detail: event.data.name, life: 3000 });
    };

    const onRowCollapse = (event: DataTableRowEvent) => {
        toast.current?.show({ severity: 'success', summary: 'EtiquetaCursoInterface Collapsed', detail: event.data.name, life: 3000 });
    };

    const expandAll = () => {
        let _expandedRows: DataTableExpandedRows = {};

        etiquetas.forEach((p) => (_expandedRows[`${p.id}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(undefined);
    };

    const allowExpansion = (rowData: EtiquetaCursoInterface) => {
        return rowData.etiquetas!.length > 0;
    };

    const rowExpansionTemplate = (data: EtiquetaCursoInterface) => {
        return (
            <div className="p-3 bg-amber-300 rounded-xl shadow-xl">
                <div className='bg-white p-2 rounded-xl italic'>
                    <h5>Etiquetas para {data.nombre}</h5>
                </div>

                <DataTable className='border-2 rounded mt-2' value={data.etiquetas}>
                    <Column field="nombre" header="Nombre" sortable></Column>
                </DataTable>
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap justify-content-end gap-2">
            <Button icon="pi pi-plus" label="Expandir todo" onClick={expandAll} text />
            <Button icon="pi pi-minus" label="Colapsar todo" onClick={collapseAll} text />
            {renderHeader()}
        </div>
    );

    const onResponse = (data: EtiquetaCursoInterface) => {
        setEtiquetas([...etiquetas, data])
    }

    const onResponse2 = (data: EtiquetaCursoInterface) => {
        setEtiquetas([...etiquetas, data])
    }

    return (
        <div className="card border rounded-lg">
            <EtiquetaCreate visible={createDialog} setVisible={setCreateDialog} response={onResponse} />
            <EtiquetaPorCurso visible={createDialog2} setVisible={setCreateDialog2} response={onResponse2} />
            {selectedEtiqueta ?
                <>
                </>
                :
                <></>
            }
            <DataTable value={etiquetas} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}
                dataKey="id" header={header} tableStyle={{ minWidth: '60rem' }}>
                <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="nombre" header="Tipo de etiqueta" sortable style={{ width: '100%' }}></Column>
            </DataTable>
        </div>
    );
}
export default EtiquetasTable;