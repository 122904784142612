import React, { useState, useEffect, useRef } from 'react';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

import CursosService from '../../../services/CursosService';
import { CursoInterfacePost } from '../../../models/CursosModel';
import MatriculaCreate from '../Create/MatriculaCreate';
import CourseCreate from '../Create/CourseCreate';
import { CursoInterface } from '../../../models/CursosModel';
import CursoDetail from '../Detail/CursoDetail';
import Swal from 'sweetalert2';
const CursosTable: React.FC = () => {

    const [cursos, setCursos] = useState<CursoInterfacePost[]>([]);
    const [filters, setFilters] = useState<any>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const [selectedCurso, setSelectedCurso] = useState<CursoInterfacePost | null>(null);
    const [createDialog, setCreateDialog] = useState<boolean>(false);
    const [cursoVisible, setCursoVisible] = useState<boolean>(false);

    useEffect(() => {
        CursosService.getCursos().then((data: CursoInterfacePost[]) => setCursos(data));
    }, []);

    const opcionesBodyTemplate = (rowData: any) => {

        return (
            <div className="card">
                <div className="flex flex-wrap justify-content-center gap-3">
                    <Button onClick={() => detailCurso(rowData)} icon="pi pi-eye" severity="success" aria-label="Search" />
                    {/** <Button onClick={() => editMatricula(rowData)} icon="pi pi-pencil" severity="secondary" aria-label="Bookmark" />
                    <Button onClick={() => deleteMatricula(rowData)} icon="pi pi-times" severity="danger" aria-label="Cancel" /> */}
                    <Button onClick={() => deleteCurso(rowData)} icon="pi pi-times" severity="danger" aria-label="Cancel" />

                </div>
            </div>
        )
    };

    const onGlobalFilterChange = (event: any) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className="flex flex-wrap gap-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
                </span>
                <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
            </div>
        );
    };

    const openNew = () => {
        setCreateDialog(true);
    };

    const detailCurso = (curso: CursoInterfacePost) => {
        setSelectedCurso(curso);
        setCursoVisible(true);
    };

    const deleteCurso = (curso: CursoInterfacePost) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¿Quieres eliminar este curso?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                // Si el usuario confirma, eliminar la pregunta
                console.log(curso.id);
                CursosService.deleteCurso(String(curso.id));
            }
        });
    }



    const onResponse = (data: CursoInterfacePost) => {
        setCursos([...cursos, data])
    }

    const header = renderHeader();

    return (
        <div className="card border rounded-lg">
            <CourseCreate visible={createDialog} setVisible={setCreateDialog} response={onResponse} />

            {selectedCurso ?
                <>
                    <CursoDetail visible={cursoVisible} setVisible={setCursoVisible} curso={selectedCurso} />
                </>
                :
                <></>
            }
            <DataTable value={cursos} paginator rows={6} header={header} filters={filters} onFilter={(e) => setFilters(e.filters)}
                selection={selectedCurso} onSelectionChange={(e) => setSelectedCurso(e.value as CursoInterfacePost)} selectionMode="single" dataKey="id"
                emptyMessage="No se encontraron usuarios." tableStyle={{ minWidth: '50rem' }}>
                <Column field="nombre" header="Curso" sortable style={{ width: '40%' }}></Column>

                <Column field="id" header="Opciones" body={opcionesBodyTemplate} style={{ width: '20%' }}></Column>
            </DataTable>
        </div>
    );
}
export default CursosTable;