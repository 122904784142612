import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

import PreguntasService from '../../../services/PreguntasService';
import { TiposPreguntas, PreguntaInterface } from '../../../models/PreguntasModel';
import EtiquetasService from '../../../services/EtiquetasService';
import { EtiquetaDictInterface } from '../../../models/EtiquetasModel';
import PreguntaDetail from '../Detail/PreguntaDetail';
import PreguntaEdit from '../Edit/PreguntaEdit';
import FormQuestion from '../CompAdmin/Questions/FormQuestion';

import Swal from 'sweetalert2';

const PreguntasTable: React.FC = () => {
    const tiposPreguntas = TiposPreguntas;

    const [preguntas, setPreguntas] = useState<PreguntaInterface[]>([]);
    const [etiquetas, setEtiquetas] = useState<EtiquetaDictInterface>();
    const [filters, setFilters] = useState<any>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        texto: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    const [preguntaVisible, setPreguntaVisible] = useState<boolean>(false);
    const [formVisible, setformVisible] = useState<boolean>(false);
    const [preguntaEditVisible, setPreguntaEditVisible] = useState<boolean>(false);

    const [selectedPregunta, setSelectedPregunta] = useState<any>(null);


    useEffect(() => {
        PreguntasService.getPreguntas().then((data) => setPreguntas(data));
        EtiquetasService.getEtiquetasDict().then((data) => setEtiquetas(data))
    }, []);

    const etiquetaBodyTemplate = (rowData: PreguntaInterface) => {
        const etiquetasOpciones = ["danger", "success", "info", "warning"];
        return rowData.etiquetas.map((row: string) => {
            return (<Tag value={etiquetas?.[row]} severity={etiquetasOpciones[(row.charCodeAt(0) % 4)] as "danger" | "success" | "info" | "warning"} />);
        });
    };

    const opcionesBodyTemplate = (rowData: PreguntaInterface) => {

        return (
            <div className="card">
                <div className="flex flex-wrap justify-content-center gap-3 mb-4">
                    <Button onClick={() => detailPregunta(rowData)} icon="pi pi-eye" severity="success" aria-label="Search" />
                    <Button onClick={() => editPregunta(rowData)} icon="pi pi-pencil" severity="secondary" aria-label="Bookmark" />
                    <Button onClick={() => deletePregunta(rowData)} icon="pi pi-times" severity="danger" aria-label="Cancel" />

                </div>
            </div>
        )
    };

    const tipoBodyTemplate = (rowData: any) => {
        const data: string[] = tiposPreguntas[rowData.tipo];
        return <Tag value={data[0]} severity={data[1] as "danger" | "success" | "info" | "warning"} />;
    };

    const onGlobalFilterChange = (event: any) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const openNew = () => {
        setformVisible(true);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className="flex flex-wrap gap-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
                </span>
                <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
            </div>
        );
    };

    const header = renderHeader();

    const detailPregunta = (pregunta: PreguntaInterface) => {
        setSelectedPregunta(pregunta);
        setPreguntaVisible(true);
    }

    const editPregunta = (pregunta: PreguntaInterface) => {
        setSelectedPregunta(pregunta);
        setPreguntaEditVisible(true);
    }

    const deletePregunta = (pregunta: PreguntaInterface) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¿Quieres eliminar esta pregunta?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                // Si el usuario confirma, eliminar la pregunta
                console.log(pregunta.id);
                PreguntasService.deletePregunta(String(pregunta.id));
            }
        });
    }

    const onResponse = (data: PreguntaInterface) => {
        setPreguntas([...preguntas, data])
    }

    return (
        <div className="card border rounded-lg">
            <PreguntaDetail visible={preguntaVisible} setVisible={setPreguntaVisible} id={selectedPregunta?.id} />
            <PreguntaEdit visible={preguntaEditVisible} setVisible={setPreguntaEditVisible} id={selectedPregunta?.id} />
            <FormQuestion visible={formVisible} setVisible={setformVisible} response={onResponse}/>
            <DataTable value={preguntas} paginator rows={6} header={header} filters={filters} onFilter={(e) => setFilters(e.filters)}
                selection={selectedPregunta} onSelectionChange={(e) => setSelectedPregunta(e.value)} selectionMode="single" dataKey="id"
                emptyMessage="No se encontraron preguntas." tableStyle={{ minWidth: '50rem' }}>
                <Column field="texto" header="Texto" sortable style={{ width: '40%' }}></Column>
                <Column field="tipo" header="Tipo" body={tipoBodyTemplate} sortable filterMenuStyle={{ width: '14rem' }} style={{ width: '20%' }}></Column>
                <Column field="status" header="Etiquetas" body={etiquetaBodyTemplate} sortable filterMenuStyle={{ width: '14rem' }} style={{ width: '20%' }}></Column>
                <Column field="id" header="Opciones" body={opcionesBodyTemplate} style={{ width: '20%' }}></Column>
            </DataTable>
        </div>
    );
}
export default PreguntasTable;