import { Toast } from 'primereact/toast';

export function showSuccess(toastRef: React.RefObject<Toast>, message: string) {
  toastRef.current?.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
}

export function showInfo(toastRef: React.RefObject<Toast>, message: string) {
  toastRef.current?.show({ severity: 'info', summary: 'Info', detail: message, life: 3000 });
}

export function showWarn(toastRef: React.RefObject<Toast>, message: string) {
  toastRef.current?.show({ severity: 'warn', summary: 'Warning', detail: message, life: 3000 });
}

export function showError(toastRef: React.RefObject<Toast>, message: string) {
  toastRef.current?.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
}
