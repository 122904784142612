import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";

interface PropsBarchart{
  etiquetas: string[];
  correctas: number[];
  incorrectas: number[];
}

const BarChart: React.FC<PropsBarchart> = ({etiquetas, correctas, incorrectas}) => {
  const options: ApexOptions = {
    chart: {
      type: "bar",
      width: "100%",
      height: 400,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: etiquetas,
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
        },
      },
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: -20,
      },
    },
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      name: "Correctas",
      color: "#31C48D",
      data: correctas,
    },
    {
      name: "Incorrectas",
      data: incorrectas,
      color: "#F05252",
    },
  ];

  return (
    <div className="mx-auto w-3/5 overflow-hidden">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={400}
      />
    </div>
  );
};

export default BarChart;
