import Swal from 'sweetalert2';
import api from './api';

class ExamenesService {

    public patchExamen(examenId: string, respuesta: any, matriculaId:string) {
        return api.patch(`examenes/${matriculaId}/${examenId}/`, { respuestas: [respuesta] })
            .then(response => response.data)
            .catch(error => {
                console.error("Error al subir la pregunta:", error);
            });
    };

    public postExamen(tipo: string, data: any) {
        return api.post(`${tipo}/`, data)
            .then(response => response.data)
            .catch((error) => {
                console.error('Error al crear el examen:', error);
                Swal.fire({
                    title: '¡Atención!',
                    text: 'Ocurrio un error, tal vez la cantidad de preguntas superior a las que están en nuestro sistema.',
                    icon: 'info',
                    confirmButtonText: 'OK',
                  });
            });
    };

    public corregirExamen(examenId: string, matriculaId:string) {
        return api.get(`examenes/${matriculaId}/correccion/${examenId}/`)
            .then(response => response.data)
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    public getExamen(examenId: string, matriculaId:string) {
        return api.get(`examenes/${matriculaId}/${examenId}/`)
            .then(response => response.data)
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    public getExamenByTipo(tipo: string, cursoId: string) {
        return api.get(`${tipo}${cursoId}/`)
            .then(response => response.data)
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    public getResultadoExamen(examenId: string, matriculaId:string) {
        return api.get(`examenes/${matriculaId}/resultado/${examenId}/`)
            .then(response => response.data)
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    public getHistorialExamenes(cursoId: string, matriculaId:string) {
        return api.get(`examenes/${matriculaId}/historial/${cursoId}/`)
            .then(response => response.data)
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }
}

export default new ExamenesService();
