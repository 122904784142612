import { useState, useEffect } from "react";
import { Answer } from "../../models/PreguntasModel";

interface Option {
    [key: string]: string | number | undefined;
    peso?: number;
}

interface MultipleChoiceProps {
    id: string;
    editable: boolean;
    texto: string;
    options: Option[];
    examen?: string;
    respuestas?: Answer;
    handlePost?: (respuesta: any, examencod: string) => void;
    onButtonClick?: (id: string) => void;
    next?: () => void;
}

const MultipleChoice: React.FC<MultipleChoiceProps> = ({ id, texto, options, examen = '', next,respuestas, onButtonClick, handlePost, editable }) => {

    const [selected, setSelected] = useState('');
    const handleSelect = (id: string) => {
        setSelected(id);
    }

    useEffect(() => {
        const alternativaRespuesta = (respuestas && respuestas.solucion && respuestas.solucion[0].alternativa) ?? ''
        setSelected(alternativaRespuesta);
    }, [id]);

    const handleSaveButtonClick = () => {
        const selectedOption = options.find(option => Object.keys(option)[0] === selected);
        let choiseSelect = ''
        const selectedOptionKey = selectedOption ? Object.keys(selectedOption)[0] : '';
        if (selectedOptionKey !== '') {
            choiseSelect = selectedOptionKey;
        }
        const respuesta = {
            solucion: [
                {
                    peso: 1,
                    alternativa: choiseSelect
                }
            ],
            pregunta: id
        };
        console.log(selectedOption, respuesta)

        handlePost?.(respuesta, examen);
        onButtonClick?.(id);
        if(next){
            next();
        }
    }

    return (
        <div>
            <h1 className="text-xl font-semibold mb-4 ">{texto}</h1>
            <div>
                {options.map((option, index) => {
                    const [key, value] = Object.entries(option)[0];
                    return (
                        <div key={key} onClick={() => { if (editable) handleSelect(key); }} className="flex my-2 cursor-pointer">
                            <div className={`${selected === key ? (option.peso === 1 ? "bg-green-100" : "bg-red-100") : (option.peso === 1 ? "bg-green-100" : "bg-gray-100")} rounded-l p-2 font-bold text-gray-500 mx-1 text-2xl`}>
                                {index + 1}
                            </div>
                            <div className={`${selected === key ? (option.peso === 1 ? "bg-green-100" : "bg-red-100") : (option.peso === 1 ? "bg-green-100" : "bg-gray-100")} rounded-r p-4 text-gray-500 flex-1`}>{value}</div>
                        </div>
                    )
                })}
                {editable ?
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
                        onClick={handleSaveButtonClick}
                    >
                        Siguiente
                    </button>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

export default MultipleChoice;