import React, { useState, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import CursosService from "../../../services/CursosService"; // Ajusta la ruta según tu estructura de carpetas
import { showSuccess, showError } from "../../../services/ToastService";
import { Toast } from "primereact/toast";

import { FileUpload } from 'primereact/fileupload';


interface PropsCourseCreate {
    visible: boolean;
    setVisible: (activo: boolean) => void;
    response: (data: any) => void;
}

const CourseCreate: React.FC<PropsCourseCreate> = ({ visible, setVisible, response }) => {
    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [imagen_url, setImagen_url] = useState<string | null>('');

    const [submit, setSubmit] = useState(false);
    const toast = useRef<Toast>(null);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmit(true);

        if (nombre !== '' && descripcion !== '' && imagen_url !== '') {
            const data = {
                nombre: nombre,
                descripcion: descripcion,
                imagen_url: imagen_url,
                // Otros campos necesarios para la creación de cursos
            };

            CursosService.postCurso(data)
                .then(createdCourse => {
                    response(createdCourse);
                    setVisible(false);
                    setNombre('');
                    setDescripcion('');
                    setImagen_url(null);
                    setSubmit(false);
                    showSuccess(toast, 'Curso Creado');
                })
                .catch(error => {
                    console.error(error);
                    showError(toast, 'Error al crear el curso');
                });
        }
    }

    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast} />
            <Dialog header="Nuevo Curso" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6" action="#">
                                <div>
                                    <label htmlFor="nombre" className="block mb-2 text-sm font-medium text-gray-900">Nombre del curso</label>
                                    <input
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                        type="text"
                                        name="nombre"
                                        id="nombre"
                                        className={`bg-gray-50 border text-gray-900 ${submit ? (nombre !== '' ? 'border-gray-300' : 'border-red-300') : ' border-gray-300'} sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                                        placeholder="Nombre del curso"
                                    />
                                    <div className="text-xs text-red-600">
                                        {submit ? (nombre !== '' ? '' : '* El nombre del curso es requerido.') : ''}
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="descripcion" className="block mb-2 text-sm font-medium text-gray-900">Descripción del curso</label>
                                    <textarea
                                        value={descripcion}
                                        onChange={(e) => setDescripcion(e.target.value)}
                                        name="descripcion"
                                        id="descripcion"
                                        className={`bg-gray-50 border text-gray-900 ${submit ? (descripcion !== '' ? 'border-gray-300' : 'border-red-300') : ' border-gray-300'} sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                                        placeholder="Descripción del curso"
                                    />
                                    <div className="text-xs text-red-600">
                                        {submit ? (descripcion !== '' ? '' : '* La descripción del curso es requerida.') : ''}
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="imagen_url" className="block mb-2 text-sm font-medium text-gray-900">URL de la imagen del curso</label>
                                    <input
                                        value={imagen_url === null ? '' : imagen_url}
                                        onChange={(e) => setImagen_url(e.target.value)}
                                        type="text"
                                        name="imagen_url"
                                        id="imagen_url"
                                        className={`bg-gray-50 border text-gray-900 ${submit ? (imagen_url !== '' ? 'border-gray-300' : 'border-red-300') : ' border-gray-300'} sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                                        placeholder="URL de la imagen del curso"
                                    />

                                </div>

                                {/* Otros campos según tus necesidades */}
                                <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                    Crear Curso
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
};

export default CourseCreate;
