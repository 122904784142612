import api from './api';

class CursosService {

  public getCursosByUser(user_id: string) {
    return api.get(`cursos/${user_id}/`)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }



  public postCurso(data: any) {
    return api.post('curso-detail/', data)
      .then(response => response.data)
      .catch((error) => {
        console.error(error)
      });
  }


  public getCursos() {
    return api.get(`curso-detail/`)
      .then(response => response.data)
      .catch((error) => {
        console.error(error)
      });
  }

  public deleteCurso(curso_id: string) {
    return api.delete(`curso-detail/${curso_id}`)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  public getMatriculas() {
    return api.get(`usuario-curso/`)
      .then(response => response.data)
      .catch((error) => {
        console.error(error)
      });
  }

  public postMatriculas(data: any) {
    return api.post(`usuario-curso/`, data)
      .then(response => response.data)
      .catch((error) => {
        console.error(error)
      });
  }

  public postMatriculaUser(curso: string) {
    const data = {"curso_id":curso} 
    return api.post(`register/usuario-curso/`, data)
      .then(response => response.data)
      .catch((error) => {
        console.error(error)
      });
  }

  public getCursoById(curso_id: string) {
    return api.get(`cursos/${curso_id}/`)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching curso data:", error);
      });
  }

  public putMatriculas(id: string, data: any) {
    return api.put(`usuario-curso/${id}/`, data)
      .then(response => response.data)
      .catch((error) => {
        console.error(error)
      });
  }
}

export default new CursosService();
