import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ExamenesService from "../../services/ExamenesService";
import PreguntasService from "../../services/PreguntasService";
import axios from "axios";

interface Elementodata {
    id: string;
    nombre: string;
}


interface CreateSessionProps {
    data: Etiquetas[];
    label: string;
    type: string;
    onClose: () => void;
}

interface Etiquetas {
    id: string;
    nombre: string;
}


export default function CreateSession({ data, onClose, label, type }: CreateSessionProps) {
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [name, setName] = useState(type === 'pruebas' ? 'Prueba' : 'Examen'); // Estado para el nombre
    const [cantidad, setCantidad] = useState(0); // Estado para la cantidad
    const [maximo, setMaximo] = useState(50)
    const navigate = useNavigate();

    useEffect(() => {
        console.log(sessionStorage.getItem('selectedCourseId'))
        const curso = sessionStorage.getItem('selectedCourseId')
        console.log(`http://127.0.0.1:8000/api/v1/contador/${String(curso)}`)
        /*axios.get(`http://127.0.0.1:8000/api/v1/contador/${String(curso)}`)
            .then((response) => {
                // Puedes acceder a los datos con seguridad aquí
                const data = response.data;
                console.log(data.contador);
                setMaximo(data.contador);
            })
            .catch((error) => {
                console.error(error);
            });*/
        PreguntasService.getPreguntasCount('C3LCfgZ9zBiBLwwkhBx3ME')
            .then((data) => console.log("data:",data))
            .catch((error) => console.log(error));
    }, []);

    const upData = () => {

        try {
            console.log(sessionStorage.getItem('selectedCourse'))
            // Datos para el examen aleatorio
            const examenData = {
                nombre: name, // Cambia esto por lo que necesites
                cantidad: cantidad,
                etiqueta: data[0].id === 'random' ? 'random' : selectedOption,
                usuariocurso_id: sessionStorage.getItem('selectedCourse'), // Cambia esto por la etiqueta correcta
                curso: sessionStorage.getItem('selectedCourseId')
                // Otras propiedades necesarias para el examen aleatorio
            };


            // Realizar la solicitud POST para crear el examen aleatorio
            ExamenesService.postExamen(type, examenData).then((data) => {
                // Aquí podrías mostrar algún mensaje de éxito o redirigir al usuario
                console.log('Examen creado exitosamente:', data);
                navigate(`/pruebas/usuario-curso/${data.id}`);
            }).catch((error) => console.log(error));

        } catch (error) {
            console.error('Try fallo:', error);
        }
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
        console.log(event.target.value)
    };

    return (
        <div className="w-full flex items-center justify-center ">
            <div className="absolute bg-black opacity-75 inset-0 z-0"></div>

            <div className="absolute  bg-white shadow rounded py-12 lg:px-28 px-8">
                <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700">Nueva sesión</p>
                {data[0].id !== 'random' ?
                    <div>
                        <div className="w-full flex flex-col mt-8">
                            <label className="text-base font-semibold leading-none text-gray-800">{label}</label>
                            <select onChange={handleSelectChange} id="countries" className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100">
                                {data?.map((tag) => {
                                    return (
                                        <option key={tag.id} value={tag.id} >{tag.nombre}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    :
                    <></>
                }
                <div>
                    <div className="w-full flex flex-col mt-8">
                        <label className="text-base font-semibold leading-none text-gray-800">Número de preguntas</label>
                        <input
                            tabIndex={0}
                            arial-label="Please input a number"
                            type="number"
                            min="0"
                            max={maximo}
                            value={cantidad}
                            onChange={(e) => {
                                const inputValue = Number(e.target.value);
                                if (inputValue >= 0 && inputValue <= maximo) {
                                    setCantidad(inputValue);
                                }
                            }}
                            className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100" />
                    </div>
                </div>
                <div>
                    <div className="w-full flex flex-col mt-8">
                        <label className="text-base font-semibold leading-none text-gray-800">Nombre de la prueba o el examen</label>
                        <input tabIndex={1} arial-label="Please input a name" type="text" value={name} onChange={(e) => setName(String(e.target.value))} className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100" />
                    </div>
                </div>
                <div className="flex items-center justify-center w-full py-9">
                    <button onClick={upData} className="m-2 text-base font-semibold leading-none text-white py-4 px-10 bg-emerald-700 rounded hover:bg-emerald-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none">Crear</button>
                    <button onClick={onClose} className="m-2 text-base font-semibold leading-none text-white py-4 px-10 bg-pink-700 rounded hover:bg-pink-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none">Cancelar</button>
                </div>
            </div>
        </div>


    );
}