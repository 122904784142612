import React, { useState, useEffect, useRef } from "react";

import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";

import axios from "axios";
import { API } from "../../services/api";

interface propsUserCreate {
    response: (data: any) => void;
}

const SignIn: React.FC<propsUserCreate> = ({ response }) => {

    const [passwordSign, setPasswordSign] = useState('');
    const [passwordSign2, setPasswordSign2] = useState('');
    const [usernameSign, setUserNameSign] = useState('');
    const [firstNameSign, setFirstNameSign] = useState('');
    const [lastNameSign, setLastNameSign] = useState('');
    const [emailSign, setEmailSign] = useState('');
    const [submit, setSubmit] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmit(true);
        if (emailSign !== '' && lastNameSign !== '' && firstNameSign !== '' && usernameSign !== '' && passwordSign !== '' && passwordSign2 !== '' && passwordSign === passwordSign2) {
            const data = {
                email: emailSign,
                last_name: lastNameSign,
                first_name: firstNameSign,
                username: usernameSign,
                password: passwordSign,
            }
            postUserFree(data).then(data => {
                response({"username": usernameSign, "password": passwordSign });
                setPasswordSign('');
                setPasswordSign2('');
                setUserNameSign('');
                setFirstNameSign('');
                setLastNameSign('');
                setEmailSign('');
                setSubmit(false);

            }).catch((error: any) => {
                console.log(error);
            });
        }

    }

    const postUserFree = (data: any) => {
        return axios.post(`${API}user/free/`, data)
            .then(response => response.data)
            .catch(error => {
                throw (error);
            });
    };

    return (
        <div className="card flex justify-content-center">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6" action="#">
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Correo electronico</label>
                                <input value={emailSign} onChange={(e) => setEmailSign(e.target.value)} type="email" name="email" id="email" className={`bg-gray-50 border text-gray-900 ${submit ? (emailSign !== '' ? 'border-gray-300' : 'border-red-300') : ' border-gray-300'} sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`} placeholder="usuario@dominio.com" />
                                <div className="text-xs text-red-600">
                                    {submit ? (emailSign !== '' ? '' : '* El correo electronico es requerido.') : ''}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">Nombre de usuario</label>
                                <input value={usernameSign} onChange={(e) => setUserNameSign(e.target.value)} type="text" name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="usuario" />
                                <div className="text-xs text-red-600">
                                    150 caracteres o menos. Solo letras, digitos y @/./+/-/_.
                                </div>
                                <div className="text-xs text-red-600">
                                    {submit ? (usernameSign !== '' ? '' : '* Los nombres son requeridos.') : ''}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900">Nombres</label>
                                <input value={firstNameSign} onChange={(e) => setFirstNameSign(e.target.value)} type="text" name="firstName" id="firstName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="usuario" />
                                <div className="text-xs text-red-600">
                                    {submit ? (firstNameSign !== '' ? '' : '* Los apellidos son requeridos.') : ''}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900">Apellidos</label>
                                <input value={lastNameSign} onChange={(e) => setLastNameSign(e.target.value)} type="text" name="lastName" id="lastName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="usuario" />
                                <div className="text-xs text-red-600">
                                    {submit ? (lastNameSign !== '' ? '' : '* El nombre de usuario es requerido.') : ''}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="passwordSign" className="block mb-2 text-sm font-medium text-gray-900">Contraseña</label>
                                <input value={passwordSign} onChange={(e) => setPasswordSign(e.target.value)} type="password" name="passwordSign" id="passwordSign" placeholder="••••••••" className={`bg-gray-50 border ${submit ? (passwordSign !== '' ? 'border-gray-300' : 'border-red-300') : ' border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`} />
                                <div className="text-xs text-red-600">
                                    Tu contraseña no puede ser muy similar a tu otra información personal.<br />
                                    Tu contraseña debe contener al menos 8 caracteres.<br />
                                    Tu contraseña no puede ser una contraseña de uso común.<br />
                                    Tu contraseña no puede ser completamente numérica.<br />
                                </div>
                                <div className="text-xs text-red-600">
                                    {submit ? (passwordSign !== '' ? '' : '* La contraseña es requerida.') : ''}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="confirm-passwordSign" className="block mb-2 text-sm font-medium text-gray-900">Confirmar contraseña</label>
                                <input value={passwordSign2} onChange={(e) => setPasswordSign2(e.target.value)} type="password" name="confirm-passwordSign" id="confirm-passwordSign" placeholder="••••••••" className={`bg-gray-50 border ${submit ? (passwordSign2 !== '' ? 'border-gray-300' : 'border-red-300') : ' border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`} />
                                <div className="text-xs text-red-600">
                                    {submit ? (passwordSign2 !== '' ? '' : '* La confirmacion de contraseña es requerida.') : ''}
                                </div>
                                <div className="text-xs text-red-600">
                                    {passwordSign !== passwordSign2 ? '* La contraseña es diferente' : ''}
                                </div>
                            </div>
                            <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Crear cuenta</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )

};
export default SignIn;