import UnitCourse from "./UnitCourse"
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import UnitCourseWithourRegistering from "./UnitCourseWithourRegistering";
import Footer from "../../static/Footer";

import CursosService from "../../services/CursosService";

export default function SetOfCourses() {

    const navigate = useNavigate();
    const [courses, setCourses] = useState({ cursos_inscritos: [], cursos_no_inscritos: [] });
    const user_id = sessionStorage.getItem('user_id') ?? '';

    useEffect(() => {

        CursosService.getCursosByUser(user_id).then((data) => setCourses(data));
    }, []);

   


    const handleCourseClick = (course: string, course_id: string) => {
        sessionStorage.setItem('selectedCourse', course);
        sessionStorage.setItem('selectedCourseId', course_id);
        navigate(`/curso/${course}/`);
    };




    return (
        <>

            <div className="min-h-screen 2xl:container  2xl:mx-auto lg:px-20 md:py-12 md:px-6 py-9 px-4 ">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 gap-6">
                    {courses.cursos_inscritos.map((course: { curso: string, fecha_caducidad: string, id: string, imagen_url: string, curso_id: string, aceptado: boolean }, courseIndex) => (
                        <UnitCourse image={course.imagen_url} key={courseIndex} id={course.id} course_id={course.curso_id} name={String(course.curso)} aceptado={course.aceptado} dateExpiry={course.fecha_caducidad} row={((courseIndex % 3) + 1)} onCourseClick={handleCourseClick} />
                    ))}
                    {courses.cursos_no_inscritos.map((course: { id:string, curso: string, fecha_caducidad: string, imagen_url: string }, courseIndex) => (
                        <UnitCourseWithourRegistering id={course.id} image={course.imagen_url} key={courseIndex + courses.cursos_inscritos.length} name={String(course.curso)} row={((courseIndex + courses.cursos_inscritos.length) % 3) + 1} />
                    ))}
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </>
    );
}