import ElementPagination from "./ElementPagination";

interface PaginationProps {
    handleSelect: (id: number) => void;
    preguntas: Pregunta[];
    respuestas?: Respuesta[];
    selected: number;
}

interface Pregunta {
    id: string;
    complete?: boolean;
}

interface Respuesta{
    id: string;
    correcto?: boolean;
    solucion: Solucion[];
    pregunta: string;
}

interface Solucion{
    id: string;
    peso: number;
    alternativa?: string;
}

const Pagination: React.FC<PaginationProps> = ({ handleSelect, preguntas, respuestas, selected}) => {
    return (
        <div className="py-2 overflow-y-auto max-h-[calc(50vh-3rem)]">
            <h2 className="mb-2 text-lg font-semibold text-gray-900">Preguntas:</h2>
            <ul className="max-w-md space-y-1 text-gray-500 list-inside">
                {preguntas.map((pregunta, index) => {
                    const existePreguntaEspecifica = respuestas?.some((respuesta) => respuesta.pregunta === pregunta.id) ?? false;
                    return (
                        <ElementPagination complete={existePreguntaEspecifica} index={index} handleSelect={handleSelect} id={pregunta.id} selected={selected} />
                    )
                })}
            </ul>
        </div>
    )
}
export default Pagination;
