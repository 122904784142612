import React, { useState, useEffect, useRef } from 'react';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

import CursosService from '../../../services/CursosService';
import { MatriculaInterface } from '../../../models/CursosModel';
import MatriculaCreate from '../Create/MatriculaCreate';

const MatriculasTable: React.FC = () => {

    const [matriculas, setMatriculas] = useState<MatriculaInterface[]>([]);
    const [modeMatricula, setModeMatricula] = useState(0);
    const [filters, setFilters] = useState<any>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        fecha_caducidad: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'usuario.email': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'curso.nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const [selectedMatricula, setSelectedMatricula] = useState<any>(null);
    const [selectedMatriculaIndex, setSelectedMatriculaIndex] = useState(-1);
    const [createDialog, setCreateDialog] = useState<boolean>(false);

    useEffect(() => {
        CursosService.getMatriculas().then((data: MatriculaInterface[]) => {
            setMatriculas(data);
            console.log(data);
        });
    }, []);

    const opcionesBodyTemplate = (rowData: any) => {

        return (
            <div className="card">
                <div className="flex flex-wrap justify-content-center gap-3">
                    <Button onClick={() => detailMatricula(rowData)} icon="pi pi-eye" severity="success" aria-label="Search" />
                    <Button onClick={() => editMatricula(rowData)} icon="pi pi-pencil" severity="secondary" aria-label="Search" />
                    {/** <Button onClick={() => editMatricula(rowData)} icon="pi pi-pencil" severity="secondary" aria-label="Bookmark" />
                    <Button onClick={() => deleteMatricula(rowData)} icon="pi pi-times" severity="danger" aria-label="Cancel" /> */}

                </div>
            </div>
        )
    };

    const aceptadoBodyTemplate = (rowData: any) => {

        return (
            rowData.aceptado ? <Tag severity='success' >Verificado</Tag> : <Tag severity='danger'>No verificado</Tag>
        )
    };

    const onGlobalFilterChange = (event: any) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className="flex flex-wrap gap-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
                </span>
                <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
            </div>
        );
    };

    const openNew = () => {
        setCreateDialog(true);
        setModeMatricula(0);
    };

    const detailMatricula = (matricula: MatriculaInterface) => {
        setSelectedMatricula(matricula);
        setModeMatricula(1);
        setCreateDialog(true);
    };

    const editMatricula = (matricula: MatriculaInterface) => {
        setSelectedMatriculaIndex(matriculas.findIndex(obj => obj === matricula));
        setSelectedMatricula(matricula);
        setModeMatricula(2);
        setCreateDialog(true);
    };

    const onResponse = (data: MatriculaInterface) => {
        if (modeMatricula === 0) {
            setMatriculas([...matriculas, data])
        }
        if (modeMatricula === 2) {
            if (selectedMatricula && selectedMatricula.id) {
                const matriculasTemp = matriculas;
                matriculasTemp[selectedMatriculaIndex] = data;
                setMatriculas(matriculasTemp)
                setSelectedMatricula(null);
            }
        }
    };

    const header = renderHeader();

    return (
        <div className="card border rounded-lg">
            <MatriculaCreate visible={createDialog} setVisible={setCreateDialog} response={onResponse} mode={modeMatricula} matricula={selectedMatricula} />
            <DataTable value={matriculas} paginator rows={6} header={header} filters={filters} onFilter={(e) => setFilters(e.filters)}
                selection={selectedMatricula} onSelectionChange={(e) => setSelectedMatricula(e.value)} selectionMode="single" dataKey="id"
                emptyMessage="No se encontraron usuarios." tableStyle={{ minWidth: '50rem' }}>
                <Column field="curso.nombre" header="Curso" sortable style={{ width: '20%' }}></Column>
                <Column field="aceptado" header="Verificado" body={aceptadoBodyTemplate} sortable style={{ width: '20%' }}></Column>
                <Column field="usuario.email" header="Correo Usuario" sortable filterMenuStyle={{ width: '14rem' }} style={{ width: '20%' }}></Column>
                <Column field="fecha_caducidad" header="Fecha de caducidad" sortable filterMenuStyle={{ width: '14rem' }} style={{ width: '20%' }}></Column>
                <Column field="id" header="Opciones" body={opcionesBodyTemplate} style={{ width: '20%' }}></Column>
            </DataTable>
        </div>
    );
}
export default MatriculasTable;