import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TestLayout from "./TestLayout";
import { Skeleton } from "@nextui-org/react";

import ExamenesService from "../../services/ExamenesService";

interface Alternativa {
  id: string;
  texto: string;
  peso: number;
}

interface Pregunta {
  id: string;
  texto: string;
  tipo: string;
  etiquetas: string[];
  alternativas: Alternativa[];
  complete?: boolean;
}

interface QuestionnaireProps {
  id: string;
  nombre: string;
  preguntas: Pregunta[];
  tipo: string;
  fecha: Date;
  tiempo: Date;
  finalizado: boolean;
}

const AxiosTestLayout: React.FC = () => {
  const { examenEnRuta } = useParams();
  const [examen, setExamen] = useState<QuestionnaireProps | null>(null);;
  const navigate = useNavigate()
  
  useEffect(() => {
    // Simular una demora de 3 segundos antes de completar la solicitud

    ExamenesService.getExamen(examenEnRuta ?? '',String(sessionStorage.getItem("selectedCourse"))).then((data) => setExamen(data));
  }, []);

  const handleCancel = () => {
    console.log('Examen cancelado');
    navigate(`/curso/${sessionStorage.getItem("selectedCourse")}/`);
  };

  const handleFinish = () => {
    console.log('Examen Finalizado');
    ExamenesService.corregirExamen(examenEnRuta ?? '',String(sessionStorage.getItem("selectedCourse"))).then((data) => console.log(data));
    navigate(`/resultados/usuario-curso/${examenEnRuta}/`);
  };

  if (!examen) {
    return (
      <div className="flex flex-col items-center min-h-screen bg-neutral-100 shadow-md rounded-md">
        <Skeleton className="w-5/6 bg-white text-black text-center text-xl italic p-4 rounded-md my-2 mx-4" />
        <div className="w-full md:flex md:justify-between">
          <div className="md:w-1/4 w-full p-6 bg-white sm:h-[calc(86vh-3rem)] shadow-md rounded-md mx-4 md:mb-0 mb-4">
            {/* Lado Izquierdo (con Skeleton) */}
            <Skeleton className="bg-blue-500 text-white p-4 rounded-md mb-4" />
            {/* Botón de Cancelar */}

            <div className="py-2 overflow-y-auto no-scrollbar">
              <Skeleton className="h-48" />
            </div>
          </div>

          <div className="md:w-3/4 w-full sm:h-[calc(86vh-3rem)] p-6 bg-white shadow-md rounded-md overflow-y-auto no-scrollbar">
            <Skeleton className="h-48" />


          </div>
        </div>
      </div>
    );
  } else

    return (
      <TestLayout
        name={examen.nombre}
        test={examen}
        onFinish={handleFinish}
        onCancel={handleCancel} />
    );

};

export default AxiosTestLayout;

