import React, { useState, useEffect } from "react";

import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { error } from "console";
import { PickList } from 'primereact/picklist';

import EtiquetasService from "../../../services/EtiquetasService";
import CursosService from "../../../services/CursosService";

interface propsEtiquetaPorCurso {
    visible: boolean;
    setVisible: (activo: boolean) => void;
    response: (data: any) => void;
}

const EtiquetaPorCurso: React.FC<propsEtiquetaPorCurso> = ({ visible, setVisible, response }) => {

    const [nombre, setNombre] = useState('');
    const [curso, setCurso] = useState('');
    const [etiquetas, setEtiquetas] = useState<any>([]);
    const [etiquetasSeleccionadas, setEtiquetasSeleccionadas] = useState<any>([]);
    const [cursosDisponibles, setCursosDisponibles] = useState<any[]>([])
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        if (visible) {
            EtiquetasService.getEtiquetasFull().then((data) => setEtiquetas(data));
            CursosService.getCursos().then(data => setCursosDisponibles(data));
        }
    }, [visible]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmit(true);
        if (nombre !== '' && curso !== '' && etiquetasSeleccionadas.length > 0) {
            const dataPost = {
                nombre: nombre,
                curso: curso,
                etiquetas: etiquetasSeleccionadas.map(function (obj: any) {
                    return obj.id;
                })
            }
            EtiquetasService.postEtiquetaTipo(dataPost).then(data => {
                response(data);
                setVisible(false);
                setNombre('');
                setEtiquetas([]);
                setEtiquetasSeleccionadas([]);
                setSubmit(false);
            })
        }
    }

    const onChange = (event: any) => {
        setEtiquetas(event.source);
        setEtiquetasSeleccionadas(event.target);
    };

    const itemTemplate = (item: any) => {
        return (
            <div key={item.id} className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{item.nombre}</span>
                    <div className="flex align-items-center gap-2">
                        <i className="pi pi-tag text-sm"></i>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="card flex justify-content-center">
            <Dialog header="Nueva Etiqueta Por curso" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                    <div>
                        <label htmlFor="nombre" className="block mb-2 text-sm font-medium text-gray-900">Nombre de la Etiqueta</label>
                        <input value={nombre} onChange={(e) => setNombre(e.target.value)} type="text" name="nombre" id="nombre" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Etiqueta" />
                        <div className="text-xs text-red-600">
                            {submit ? (nombre !== '' ? '' : '* El nombre es requerido.') : ''}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="curso" className="block mb-2 text-sm font-medium text-gray-900">Seleccionar Curso</label>
                        <select
                            value={curso}
                            onChange={(e) => setCurso(e.target.value)}
                            name="curso"
                            id="curso"
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        >
                            <option key="cursos" value="">Seleccione un Curso</option>
                            {cursosDisponibles.map((curso) => (
                                <option key={curso.id} value={curso.id}>
                                    {curso.nombre}
                                </option>
                            ))}
                        </select>
                        <div className="text-xs text-red-600">
                            {submit ? (curso !== '' ? '' : '* El curso es requerido.') : ''}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="etiquetas" className="block mb-2 text-sm font-medium text-gray-900">Seleccionar Etiquetas</label>
                        <PickList source={etiquetas} target={etiquetasSeleccionadas} onChange={onChange} itemTemplate={itemTemplate} filter filterBy="nombre" breakpoint="1400px"
                            sourceHeader="Etiquetas" targetHeader="Seleccionadas" sourceStyle={{ height: '30rem' }} targetStyle={{ height: '30rem' }}
                            sourceFilterPlaceholder="Buscar por nombre" targetFilterPlaceholder="Buscar por nombre" />
                    </div>
                    <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Crear Etiqueta</button>
                </form>
            </Dialog>
        </div>
    )

};
export default EtiquetaPorCurso;