import { useEffect, useState } from "react";
import React from "react";
import { Answer, Solution } from "../../models/PreguntasModel";

interface ColorOption {
    [key: string]: string;
}

interface Option {
    [key: string]: string;
}

interface OrderQuestion {
    id: string;
    editable: boolean;
    texto: string;
    options: Option[];
    examen?: string;
    respuestas?: Answer;
    handlePost?: (respuesta: any, examencod: string) => void;
    onButtonClick?: () => void;
    next?: () => void;
}

const OrderQuestion: React.FC<OrderQuestion> = ({ id, texto, options, respuestas,next, onButtonClick, examen = '', handlePost, editable }) => {

    const [orderedOptions, setOrderedOptions] = useState<Option[]>(options);
    console.log(respuestas)
    useEffect(() => {
        //algoritmo para ordenar las respuestas
        if (respuestas) {
            const newOptions: Option[] = [...options]; // Clonamos las opciones originales

            // Mapea las opciones con sus pesos
            const optionWeights: { [key: string]: number } = {};

            respuestas.solucion.forEach((solucion) => {
                if (solucion.alternativa) {
                    optionWeights[solucion.alternativa] = solucion.peso;
                }
            });

            // Ordena las opciones según los pesos
            newOptions.sort((a, b) => {
                const optionA = Object.keys(a)[0];
                const optionB = Object.keys(b)[0];
                const weightA = optionWeights[optionA] || 0;
                const weightB = optionWeights[optionB] || 0;
                return weightA - weightB;
            });
            setOrderedOptions(newOptions) // Aquí tienes las opciones ordenadas según los pesos
        }
    }, [respuestas, options]);

    const assignColorsToOptions = (options: Option[]) => {
        const colors = ['orange', 'lime', 'emerald', 'cyan', 'blue', 'violet', 'fuchsia', 'rose', 'amber', 'green'];
        const colorOptions: ColorOption = {};


        options.forEach((option, index) => {
            const key = Object.entries(option)[0][0];
            colorOptions[key] = colors[index];
        });

        return colorOptions;
    };

    const colorOptions = assignColorsToOptions(options);

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, index: number) => {
        event.dataTransfer.setData("text/plain", index.toString());
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, targetIndex: number) => {
        event.preventDefault();

        const sourceIndex = parseInt(event.dataTransfer.getData("text/plain"));
        if (isNaN(sourceIndex) || sourceIndex === targetIndex) {
            return;
        }

        const updatedOptions = [...orderedOptions];
        const [draggedOption] = updatedOptions.splice(sourceIndex, 1);
        updatedOptions.splice(targetIndex, 0, draggedOption);

        setOrderedOptions(updatedOptions);
    }

    const handleSaveButtonClick = () => {
        const orderedAlternatives = orderedOptions.map((option, index) => {
            const [key] = Object.entries(option)[0];
            return {
                alternativa: key,
                peso: index + 1,
            };
        });
        const respuesta = {
            solucion: orderedAlternatives,
            pregunta: id
        };
        console.log(respuesta)

        handlePost?.(respuesta, examen);
        onButtonClick?.(); // Llamamos a la función del componente padre para desbloquear el botón "Siguiente"
        if (next){
            next();
          }
    }

    return (
        <React.Fragment>
            <h1 className="text-xl font-semibold mb-4 ">{texto}</h1>
            <div className="bg-orange-100" />
            <div className="bg-lime-100" />
            <div className="bg-emerald-100" />
            <div className="bg-cyan-100" />
            <div className="bg-blue-100" />
            <div className="bg-violet-100" />
            <div className="bg-fuchsia-100" />
            <div className="bg-rose-100" />
            <div className="bg-amber-100" />
            <div className="bg-green-100" />
            <div>
                {orderedOptions.map((option, index) => {
                    const [key, value] = Object.entries(option)[0];
                    return (
                        <div
                            key={key}
                            className="flex my-2 cursor-pointer"
                            draggable={true}
                            onDragStart={(e) => { if (editable) handleDragStart(e, index); }}
                            onDragOver={(e) => { if (editable) handleDragOver(e, index); }}
                            onDrop={(e) => { if (editable) handleDrop(e, index); }}
                        >
                            <div className="bg-gray-100 rounded-l p-2 font-bold text-gray-500 mx-1 text-2xl">
                                {index + 1}
                            </div>
                            <div className={`rounded-r bg-${colorOptions[key]}-100 p-4 text-gray-500 flex-1`}>{value}</div>
                        </div>
                    )
                })}
            </div>
            {editable ?
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
                    onClick={handleSaveButtonClick}
                >
                    Siguiente
                </button>
                :
                <></>
            }
        </React.Fragment>
    );
}

export default OrderQuestion;
