import axios from "axios";
import { useState, useEffect } from "react";
import { API } from "../../services/api";
import { Answer, Solution } from "../../models/PreguntasModel";

interface Option {
  [key: string]: string;
}

interface TrueFalseQuestion {
  id: string;
  texto: string;
  options: Option[];
  examen?: string;
  respuestas?: Answer;
  correcto?: string;
  editable: boolean;
  handlePost?: (respuesta: any, examencod: string) => void;
  onButtonClick?: (id: string) => void;
  next?: () => void;
}

const TrueFalseQuestion: React.FC<TrueFalseQuestion> = ({ id, texto, options, examen = '', respuestas, onButtonClick, handlePost, next, editable, correcto }) => {

  const [selected, setSelected] = useState('');

  const handleSelect = (id: string) => {
    setSelected(id);
  }

  useEffect(() => {
    setSelected('');
  }, [id]);

  const handleSaveButtonClick = () => {
    const selectedOption = options.find(option => Object.keys(option)[0] === selected);
    let selectedWeight;
    if (selectedOption) {
      if (selectedOption[selected] === "Verdadero") {
        selectedWeight = 1;
      } else if (selectedOption[selected] === "Falso") {
        selectedWeight = 0;
      }
    }
    const respuesta = {
      solucion: [
        {
          peso: selectedWeight,
          alternativa: ''
        }
      ],
      pregunta: id
    };
    console.log(selectedOption, respuesta)

    handlePost?.(respuesta, examen);
    onButtonClick?.(id);
    if (next){
      next();
    }
    
  }

  if (respuestas) {
    // Verifica el peso de la respuesta y selecciona la opción correspondiente
    const pesoRespuesta = respuestas.solucion[0].peso;
    const opcionSeleccionada = pesoRespuesta === 1 ? "V" : "F";

    return (
      <div>
        <h1 className="text-xl font-semibold mb-4">{texto}</h1>
        <div>
          {options.map((option, index) => {
            const [key, value] = Object.entries(option)[0];
            const isSelected = key === opcionSeleccionada;
            return (
              <div
                key={key}
                onClick={() => { if (editable) handleSelect(key); }}
                className="flex my-2 cursor-pointer"
              >
                <div className={`rounded-l p-2 font-bold text-gray-500 mx-1 text-2xl ${isSelected ? (key === correcto ? "bg-green-100" : "bg-red-100") : (key === correcto ? "bg-green-100" : "")}`}>
                  {index + 1}
                </div>
                <div
                  className={`rounded-r p-4 text-gray-500 flex-1 ${isSelected ? (key === correcto ? "bg-green-100" : "bg-red-100") : (key === correcto ? "bg-green-100" : "")}`}
                >
                  {value}
                </div>
              </div>
            );
          })}
          {editable ?
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
              onClick={handleSaveButtonClick}
            >
              Guardar
            </button>
            :
            <></>
          }
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-xl font-semibold mb-4 ">{texto}</h1>
      <div>
        {options.map((option, index) => {
          const [key, value] = Object.entries(option)[0];
          return (
            <div key={key} onClick={() => { if (editable) handleSelect(key); }} className="flex my-2 cursor-pointer">
              <div className={`${selected === key ? "bg-red-100" : (key === correcto ? "bg-green-100" : "bg-gray-100")} rounded-l p-2 font-bold text-gray-500 mx-1 text-2xl`}>
                {index + 1}
              </div>
              <div className={`${selected === key ? "bg-red-100" : (key === correcto ? "bg-green-100" : "bg-gray-100")} rounded-r p-4 text-gray-500 flex-1`}>{value}</div>
            </div>
          )
        })}
        {editable ?
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
            onClick={handleSaveButtonClick}
          >
            Siguiente
          </button>
          :
          <></>
        }
      </div>
    </div>
  )
}

export default TrueFalseQuestion;