import React from "react";
import exit from "../../assets/Header/exit.png";
import Cookies from 'js-cookie';

const LogoutButton: React.FC = () => {
    const handleLogout = () => {
        // Elimina las cookies
        Cookies.remove('access');
        Cookies.remove('refresh');
        
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        window.location.href = '/login'; // Redirigir al usuario a la página de inicio de sesión
    };

    return (
        <button className=" 
        text-white  inline-flex p-2 " onClick={handleLogout}>
            <img src={exit} className="pr-2" />
            Cerrar sesión

        </button>
    );
};

export default LogoutButton;
