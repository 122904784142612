import React, { useState, useEffect } from "react";

import { Dialog } from 'primereact/dialog';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Button } from "primereact/button";
import { error } from "console";

import UsersService from "../../../services/UsersService";
import CursosService from "../../../services/CursosService";
import { UserInterface,SimpleUserInterface } from "../../../models/UsersModel";
import { CursoInterface } from "../../../models/CursosModel";
import { MatriculaInterface } from "../../../models/CursosModel";

interface propsMatriculaCreate {
    visible: boolean;
    matricula?: MatriculaInterface;
    mode: number; // 0 create, 1 view, 2 edit 
    setVisible: (activo: boolean) => void;
    response: (data: any) => void;
}

const MatriculaCreate: React.FC<propsMatriculaCreate> = ({ visible, setVisible, response, mode, matricula }) => {

    const [activo, setActivo] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [users, setUsers] = useState<UserInterface[]>([]);
    const [cursos, setCursos] = useState<CursoInterface[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserInterface | null>(null);
    const [selectedCurso, setSelectedCurso] = useState<CursoInterface | null>(null);
    const [date, setDate] = useState<string | Date | Date[] | null | undefined>(null);
    const titulos = ['Nueva matricula', 'Detalle matricula', 'Editar matricula']

    useEffect(() => {
        const fechaInicial = matricula?.fecha_caducidad ? new Date(matricula.fecha_caducidad) : null;
        if (mode === 0) {
            UsersService.getUsers().then((data) => setUsers(data));
            CursosService.getCursos().then((data) => setCursos(data));
            setSelectedUser(null);
            setSelectedCurso(null);
            setDate(null);
            setActivo(false);
        }
        if (mode === 1) {
            setDate(fechaInicial);
            setSelectedUser(matricula?.usuario ?? null);
            setSelectedCurso(matricula?.curso ?? null);
            setActivo(matricula?.aceptado ?? false);
            if (selectedUser !== null) {
                setUsers([selectedUser]);
            }
            if (selectedCurso !== null) {
                setCursos([selectedCurso]);
            }
        }
        if (mode === 2) {
            setDate(fechaInicial);
            setSelectedUser(matricula?.usuario ?? null);
            setSelectedCurso(matricula?.curso ?? null);
            setActivo(matricula?.aceptado ?? false);
            UsersService.getSimpleUsers().then((data) => setUsers(data));
            CursosService.getCursos().then((data) => setCursos(data));
        }
    }, [matricula, mode]);

    const handleSubmit = () => {
        setSubmit(true);
        if (submit && selectedUser && selectedCurso && date) {
            const nuevaData = {
                "usuario": selectedUser.id,
                "curso": selectedCurso.id,
                "aceptado": activo,
                "fecha_caducidad": (date as Date).toISOString().split('T')[0]
            }
            if (mode === 0) {
                CursosService.postMatriculas(nuevaData).then(data => {
                    response(data);
                    setVisible(false);
                    setSelectedUser(null);
                    setSelectedCurso(null);
                    setDate(null);
                    setSubmit(false);
                }).catch(error =>
                    console.log(error)
                );
            }
            if (mode === 2) {
                CursosService.putMatriculas(matricula?.id ?? '', nuevaData).then(data => {
                    response(data);
                    setVisible(false);
                    setSelectedUser(null);
                    setSelectedCurso(null);
                    setDate(null);
                    setSubmit(false);
                }).catch(error =>
                    console.log(error)
                );
            }



        }
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    return (
        <div className="card flex justify-content-center">
            <Dialog header={titulos[mode]} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <div className="space-y-4 md:space-y-6" >
                                <label htmlFor="user" className="block text-sm font-medium text-gray-900">Usuario</label>
                                <Dropdown
                                    disabled={mode === 1}
                                    name="user"
                                    id="user"
                                    value={selectedUser}
                                    onChange={(e: DropdownChangeEvent) => setSelectedUser(e.value)}
                                    options={users}
                                    optionLabel="email"
                                    placeholder="Selecciona un usuario"
                                    filter
                                    className="w-full md:w-14rem"
                                />
                                <div className="text-xs text-red-600">
                                    {selectedUser ? 
                                        <>
                                        nombre: {selectedUser.first_name}<br/>
                                        apellido: {selectedUser.last_name}<br/>
                                        email: {selectedUser.email}<br/>
                                        username: {selectedUser.username}
                                        </>
                                    : ''}
                                </div>
                                <div className="text-xs text-red-600">
                                    {submit ? (selectedUser ? '' : '* Seleccione un usuario.') : ''}
                                </div>
                                <label htmlFor="curso" className="block text-sm font-medium text-gray-900">Curso</label>
                                <Dropdown
                                    disabled={mode === 1}
                                    name="curso"
                                    id="curso"
                                    value={selectedCurso}
                                    onChange={(e: DropdownChangeEvent) => setSelectedCurso(e.value)}
                                    options={cursos}
                                    optionLabel="nombre"
                                    placeholder="Selecciona un curso"
                                    filter
                                    className="w-full md:w-14rem"
                                />
                                <div className="text-xs text-red-600">
                                    {submit ? (selectedCurso ? '' : '* Seleccione un curso.') : ''}
                                </div>
                                <label htmlFor="date" className="block text-sm font-medium text-gray-900">Fecha de caducidad</label>
                                <Calendar disabled={mode === 1} name="date" id="date" value={date} onChange={(e: CalendarChangeEvent) => setDate(e.value)} showButtonBar showIcon locale="es" />
                                <div className="text-xs text-red-600">
                                    {submit ? (date ? '' : '* Seleccione una fecha.') : ''}
                                </div>
                                <div className="flex">
                                    <label htmlFor="admin" className="block mb-2 text-sm font-medium text-gray-900">Activado</label>
                                    <input disabled={mode === 1} checked={activo} type="checkbox" id="admin" name="admin" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" onChange={(e) => setActivo(e.target.checked)} />
                                </div>
                                <div className="flex justify-center items-center">
                                    {mode === 0 ?
                                        <Button onClick={handleSubmit} label="Matricular" severity="info" />
                                        : mode === 2 ?
                                            <Button onClick={handleSubmit} label="Actualizar" severity="info" />
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )

};
export default MatriculaCreate;