import api from "./api";

class EtiquetasService {

  public getEtiquetasDict() {
    return api.get(`etiquetas-dict/`)
      .then(response => response.data)
      .catch(error => {
        console.error(error)
      });
  };

  public getEtiquetasFull() {
    return api.get(`etiquetas-full/`)
      .then(response => response.data)
      .catch(error => {
        console.error(error)
      });
  };

  public getEtiquetas() {
    return api.get(`etiquetas/`)
      .then(response => response.data)
      .catch((error) => {
        console.error(error)
      });
  };

  public postEtiquetas(etiqueta: any) {
    return api.post(`etiquetas/`, etiqueta)
      .then(response => response.data)
      .catch((error) => {
        console.error(error)
      });
  };

  public postEtiquetaTipo(etiqueta: any) {
    return api.post(`etiqueta-tipo/`, etiqueta)
      .then(response => response.data)
      .catch((error) => {
        console.error(error)
      });
  };

  public getEtiquetasByCurso(courseId: string) {
    return api.get(`etiquetas/${courseId}/`)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };
}

export default new EtiquetasService();
