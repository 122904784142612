import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API } from '../../services/api';
import logo from '../../assets/Login/logo.jpeg';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isTokenExpired } from './authUtils';
import LoginService from '../../services/LoginService';

import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';

import SignIn from './SignIn';
import { UserInterface } from '../../models/UsersModel';


const Login: React.FC = () => {

    const [username, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showError, setShowError] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [signIn, setSignIn] = useState(false);

    const navigate = useNavigate();

    const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | null) => {
        e?.preventDefault();
        LoginService.Login(username, password)
            .then((response) => {

                if (response.status === 200) {
                    const data = response.data;
                    Cookies.set('access', data.response.access);
                    Cookies.set('refresh', data.response.refresh);
                    sessionStorage.setItem('accessToken', data.response.access);
                    sessionStorage.setItem('refreshToken', data.response.refresh);
                    sessionStorage.setItem('user_id', data.user);

                    navigate('/setofcourse');
                    window.location.reload();
                } else {
                    setShowError(true);
                    setShowCloseButton(true);
                    setPassword('');

                    console.log('Authentication failed.');
                }
            })
            .catch((error) => {
                setShowError(true);
                setShowCloseButton(true);
                setPassword('');
                console.error('Error:', error);
            });

    };

    const onResponse = (data: any) => {
        setSignIn(false);
        setUserName(data.username);
        setPassword(data.password);
    }

    return (

        <div className="sm:min-h-screen bg-gradient-to-r from-slate-200  flex flex-col justify-center ">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div
                    className="absolute inset-0 bg-gradient-to-r from-lime-400 to-green-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                </div>
                <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto ">
                        {!signIn ?
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <h1 className="text-xl text-center italic text-orange-400 ">Login </h1>

                                        <img className="w-full h-full bg-center bg-no-repeat bg-cover rounded-r-md" src={logo} />

                                    </div>
                                    <div className="divide-y divide-gray-200">
                                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                            <div className="relative">
                                                <label className="absolute left-0 -top-3.5 text-gray-600 hover:text-amber-400 text-sm  peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2  peer-focus:text-sm">Usuario</label>

                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="text"
                                                    value={username}
                                                    onChange={handleUserNameChange}
                                                    className="peer placeholder-transparent h-10 w-full 
                                     border-b-2 border-gray-300 text-gray-900 focus:outline-none 
                                     focus:borer-rose-600"  />
                                            </div>

                                            <div className="relative">
                                                <label className="absolute left-0 -top-3.5 text-gray-600 hover:text-amber-400 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-amber-400 peer-focus:text-sm">Contraseña</label>

                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    className="peer placeholder-transparent h-10 w-full border-b-2 
                                        border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    placeholder="Password" />
                                                {/** Muestra la alerta  */}
                                                {showError && (
                                                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                                        <strong className="font-bold">¡Error!</strong>
                                                        <span className="block sm:inline"> Credenciales incorrectas. Por favor, inténtelo de nuevo.</span>
                                                        <span
                                                            className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer"
                                                            onClick={() => setShowError(false)}
                                                        >
                                                            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                <title>Close</title>
                                                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                )}

                                            </div>
                                            <div className="relative flex justify-center pt-6 ">
                                                <button type="submit" className="bg-orange-400 hover:bg-amber-400 text-white rounded-md px-3 py-2">Ingresar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <Divider layout="horizontal" className="flex md:hidden" align="center">
                                    <b>Ó</b>
                                </Divider>
                                <div className="flex justify-center">
                                    <Button onClick={() => setSignIn(true)} label="Crear cuenta" icon="pi pi-user-plus" severity="success"></Button>
                                </div>
                            </div>
                            :
                            <SignIn response={onResponse} />
                        }
                    </div>

                </div>
            </div>
        </div >
    );


}; export default Login;