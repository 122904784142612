import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import ExamenesService from '../../services/ExamenesService';

interface CountdownTimerProps {
  initialDuration: number;
  type: string;
  dateInit: Date;
}
interface Alternativa {
  id: string;
  texto: string;
  peso: number;
}

interface Pregunta {
  id: string;
  texto: string;
  tipo: string;
  etiquetas: string[];
  alternativas: Alternativa[];
  complete?: boolean;
}
interface QuestionnaireProps {
  id: string;
  nombre: string;
  preguntas: Pregunta[];
  tipo: string;
  fecha: Date;
  tiempo: Date;
  finalizado: boolean;
}
const CountdownTimer: React.FC<CountdownTimerProps> = ({ initialDuration, type, dateInit }) => {
  const [duration, setDuration] = useState(initialDuration);
  const navigate = useNavigate(); // Obtiene la función de navegación
  const { examenEnRuta } = useParams();
  const [examen, setExamen] = useState<QuestionnaireProps | null>(null);;

  useEffect(() => {
  ExamenesService.getExamen(examenEnRuta ?? '', String(sessionStorage.getItem("selectedCourse"))).then((data) => setExamen(data));
  },[]);

  useEffect(() => {
    
    const timer = setInterval(() => {
      if (duration > 0 && type === "E") {
        setDuration(duration - 1);
      } else if (type === "P") {
        setDuration(duration + 1);
      } else {
        // Cuando el contador llegue a cero, muestra un mensaje con SweetAlert
        Swal.fire({
          title: 'Tiempo agotado',
          text: 'El tiempo asignado ha terminado.',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
        clearInterval(timer); // Detiene el temporizador

        // Redirige al usuario a la página de resultados
        navigate(`/resultados/usuario-curso/${examenEnRuta}/`);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [duration, navigate]);

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  let mensajeExamen;
  const dia = (dateInit.getDate()).toString().padStart(2, '0');
  const mes = (dateInit.getMonth() + 1).toString().padStart(2, '0');
  const año = dateInit.getFullYear();

  if (type === "P") {
    mensajeExamen =
      <>
        <div className="p-4 w-full inline-flex bg-white rounded-lg border border-amber-400 drop-shadow-xl font-sans italic">
          Inicio de prueba: {dia}/{mes}/{año}
        </div>

      </>;
  } else if (type === "E") {
    mensajeExamen =
      <>
        Termine su examen antes de salir.
      </>;
  }


  return (
    <>
      <div className="p-4 mb-2 w-full inline-flex bg-white rounded-lg border border-amber-400 drop-shadow-xl font-sans italic">
        <p >Duración: </p>
        <p className='pl-2'>{formatTime(duration)}</p>
      </div>
      <div>
        {mensajeExamen}
      </div>
    </>
  );
};

export default CountdownTimer;
